/*
 * The refresh token is no more saved in the authentication slice to avoid issues when working with multiple tabs.
 * Indeed each a refresh token can only be used once and is revoked up when a new refresh token is generated. This
 * happens when same user is logged in two different tabs (each tab gets a new refresh token but the refresh token of
 * the first tab gets revoked by the refresh of the first tab). It leads to an HTTP bad request when trying to refresh
 * the token from the first tab.
 *
 * The refresh token (the last one) is thus shared between tabs using the browser's local storage.
 * Note that the token is saved per session ID, in case different users are logged in each tab.
 */
const key = (sessionId: string) => `refreshToken-${sessionId}`;

export const getRefreshToken = (sessionId: string) => {
  const refreshToken = localStorage.getItem(key(sessionId));
  if (!refreshToken) {
    throw new Error('Refresh token is missing');
  }
  return refreshToken;
};

export const saveRefreshToken = (sessionId: string, refreshToken: string | undefined) => {
  if (!refreshToken) {
    throw new Error('Refresh token not defined');
  }
  localStorage.setItem(key(sessionId), refreshToken);
};

export const removeRefreshToken = (sessionId: string) => {
  localStorage.removeItem(key(sessionId));
};
