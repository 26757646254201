import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { AuthenticationStatus, completeAuthentication, selectError, selectStatus } from 'store/authenticationSlice';
import { useIntl } from 'react-intl';
import AuthGuardSpinner from './AuthGuardSpinner';
import AuthGuardLoginRequired from './AuthGuardLoginRequired';
import AuthGuardError from './AuthGuardError';
import Labels from '../../i18n/labels';

type AuthGuardProps = {
  children: React.ReactNode;
};

export default function AuthGuard({ children }: AuthGuardProps) {
  const dispatch = useAppDispatch();
  const status = useAppSelector(selectStatus);
  const error = useAppSelector(selectError);
  const intl = useIntl();

  useEffect(() => {
    dispatch(completeAuthentication());
  }, [dispatch]);

  switch (status) {
    case AuthenticationStatus.AuthenticationError:
      return <AuthGuardError error={error || 'Unknown Error'} />;
    case AuthenticationStatus.Authenticated:
    case AuthenticationStatus.Unauthenticating:
      return <div>{children}</div>;
    case AuthenticationStatus.Unauthenticated:
      return <AuthGuardLoginRequired />;
    case AuthenticationStatus.Authenticating:
      return <AuthGuardSpinner />;
    default:
      return <AuthGuardError error={intl.formatMessage({ id: Labels.AUTH_ERROR_BODY }, { status })} />;
  }
}
