import { post } from './masClient';
import errorHandler from './errorHandler';
import {
  AdminSearchBlacklistRequest,
  AdminSearchBlacklistResponse,
  AdminDeleteBlacklistEntryRequest,
} from '../types/Blacklist';

export const searchBlacklist = async (
  request: AdminSearchBlacklistRequest,
  accessToken: string,
): Promise<AdminSearchBlacklistResponse> =>
  post(`/v1/admin/blacklist/search`, accessToken, request).catch(errorHandler);

export const deleteBlacklistEntry = async (
  request: AdminDeleteBlacklistEntryRequest,
  accessToken: string,
): Promise<void> => post(`/v1/admin/blacklist/delete`, accessToken, request).catch(errorHandler);
