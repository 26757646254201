import { Button, Flex, useColorModeValue } from '@chakra-ui/react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { AuthenticationStatus, login, logout, selectStatus } from '../store/authenticationSlice';
import Labels from '../i18n/labels';
import RoleSelector from '../components/role/RoleSelector';

function Navbar() {
  const dispatch = useAppDispatch();
  const status = useAppSelector(selectStatus);
  const displayLogout =
    status === AuthenticationStatus.Authenticated || status === AuthenticationStatus.Unauthenticating;
  const displayLogin =
    status === AuthenticationStatus.Unauthenticated ||
    status === AuthenticationStatus.Authenticating ||
    status === AuthenticationStatus.AuthenticationError;

  return (
    <Flex
      ml={{ base: 60 }}
      px={{ base: 4 }}
      height="20"
      alignItems="center"
      bg={useColorModeValue('white', 'gray.900')}
      borderBottomWidth="1px"
      borderBottomColor={useColorModeValue('gray.200', 'gray.700')}
      justifyContent={{ base: 'flex-end' }}
    >
      {displayLogin && (
        <Button
          colorScheme="teal"
          variant="outline"
          onClick={() => dispatch(login())}
          isLoading={status === AuthenticationStatus.Authenticating}
          data-testid="loginButton"
        >
          <FormattedMessage id={Labels.NAVBAR_LOGIN_BUTTON} />
        </Button>
      )}
      {displayLogout && (
        <>
          <RoleSelector />
          <Button
            colorScheme="teal"
            variant="outline"
            onClick={() => dispatch(logout())}
            isLoading={status === AuthenticationStatus.Unauthenticating}
            data-testid="logoutButton"
          >
            <FormattedMessage id={Labels.NAVBAR_LOGOUT_BUTTON} />
          </Button>
        </>
      )}
    </Flex>
  );
}

export default Navbar;
