import HttpError from './HttpError';
import { get, post } from './masClient';
import errorHandler from './errorHandler';
import { MosupAssetType, MosupReadResponse, MosupUploadRequest } from '../types/Mosup';

export const read = async (type: MosupAssetType, accessToken: string): Promise<MosupReadResponse> =>
  get(`/v1/admin/mosup/${type}`, accessToken).catch(errorHandler);

export const upload = async (type: MosupAssetType, request: MosupUploadRequest, accessToken: string): Promise<void> =>
  post(`/v1/admin/mosup/${type}/upload`, accessToken, request).catch(errorHandler);

export const promote = async (type: MosupAssetType, accessToken: string): Promise<void> =>
  post(`/v1/admin/mosup/${type}/promote`, accessToken).catch(errorHandler);

export const fetchFile = async (url: string) =>
  fetch(url, {
    method: 'get',
    mode: 'cors',
  }).then(async (response) => {
    if (response.ok) {
      return response.text();
    }

    const body = await response.text();
    throw new HttpError(response.status, body);
  });
