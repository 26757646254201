import * as React from 'react';
import { Box, Button, Heading, Text } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import Labels from '../../i18n/labels';
import Routes from '../../routes';

function NotFoundScreen() {
  const navigate = useNavigate();

  return (
    <Box textAlign="center" py={10} px={6}>
      <Heading display="inline-block" as="h2" size="2xl" bgColor="teal" backgroundClip="text">
        404
      </Heading>
      <Text fontSize="18px" mt={3} mb={2}>
        <FormattedMessage id={Labels.NOT_FOUND_TITLE} />
      </Text>
      <Text color="gray.500" mb={6}>
        <FormattedMessage id={Labels.NOT_FOUND_SUBTITLE} />
      </Text>
      <Button colorScheme="teal" color="white" variant="solid" onClick={() => navigate(Routes.HOME)}>
        <FormattedMessage id={Labels.NOT_FOUND_BUTTON} />
      </Button>
    </Box>
  );
}

export default NotFoundScreen;
