import * as React from 'react';
import { Select } from '@chakra-ui/react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { selectAvailableRoles, selectRole, selectSelectedRole } from '../../store/authenticationSlice';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import Labels from '../../i18n/labels';
import Role from '../../types/Role';
import MasRoute from '../../routes';

const roleLabels = {
  [Role.FraudOfficer]: Labels.ROLE_FRAUD_OFFICER,
  [Role.SecondLineSupportAgent]: Labels.ROLE_SECOND_LINE_SUPPORT_AGENT,
  [Role.FirstLineSupportAgent]: Labels.ROLE_FIRST_LINE_SUPPORT_AGENT,
  [Role.OperationManager]: Labels.ROLE_OPERATION_MANAGER,
  [Role.MosupUpload]: Labels.ROLE_MOSUP_UPLOAD,
  [Role.MosupPromote]: Labels.ROLE_MOSUP_PROMOTE,
};

function RoleSelector() {
  const intl = useIntl();
  const availableRoles = useAppSelector(selectAvailableRoles);
  const selectedRole = useAppSelector(selectSelectedRole);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  return (
    <Select
      maxW="300px"
      pr="5px"
      borderColor="teal"
      color="teal"
      fontWeight="bold"
      value={selectedRole}
      onChange={(e) => {
        dispatch(selectRole(e.target.value as Role));
        // Redirect to home after changing role as the role may not have access to the current page
        navigate(MasRoute.HOME);
      }}
      data-testid="roleSelector"
    >
      {availableRoles.map((role: Role) => (
        <option value={role} key={role}>
          {intl.formatMessage({ id: roleLabels[role] })}
        </option>
      ))}
    </Select>
  );
}

export default RoleSelector;
