import HttpError from './HttpError';

const masBaseUrl = process.env.MAS_BASE_URL;
const DEFAULT_TIMEOUT = 90000; // 90sec

type Method = 'GET' | 'POST' | 'PUT' | 'DELETE';

const isJsonResponse = (response: Response) => {
  const contentType = response.headers.get('content-type');
  return contentType && contentType.indexOf('application/json') !== -1;
};

const decodeResponse = (response: Response) => {
  if (response.ok) {
    if (isJsonResponse(response)) {
      return response.json();
    }
    return response.text();
  }
  return response.text().then((body) => {
    throw new HttpError(response.status, body);
  });
};

const serializeBody = (body: object | string | undefined) => {
  if (body === undefined) {
    return undefined;
  }

  if (typeof body === 'string') {
    return body;
  }

  return JSON.stringify(body);
};

const call = async (method: Method, path: string, accessToken: string, body?: object | string) => {
  const controller = new AbortController();
  const timeoutId = setTimeout(() => controller.abort(), DEFAULT_TIMEOUT);

  return fetch(`${masBaseUrl}${path}`, {
    method,
    signal: controller.signal,
    body: serializeBody(body),
    headers: new Headers({
      Authorization: `Bearer ${accessToken}`,
      'content-type': 'application/json',
    }),
  })
    .then((response) => decodeResponse(response))
    .finally(() => {
      clearTimeout(timeoutId);
    });
};

const get = (path: string, accessToken: string) => call('GET', path, accessToken);
const post = (path: string, accessToken: string, body?: object | string) => call('POST', path, accessToken, body);
const put = (path: string, accessToken: string, body?: object | string) => call('PUT', path, accessToken, body);
const del = (path: string, accessToken: string) => call('DELETE', path, accessToken);

export { get, post, put, del };
