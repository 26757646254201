import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Role from '../../types/Role';
import Routes from '../../routes';
import { useAppSelector } from '../../store/hooks';
import { selectSelectedRole } from '../../store/authenticationSlice';

const defaultRoutes = {
  [Role.FraudOfficer]: Routes.SEARCH_USER,
  [Role.FirstLineSupportAgent]: Routes.SEARCH_USER,
  [Role.SecondLineSupportAgent]: Routes.SEARCH_USER,
  [Role.OperationManager]: Routes.TERMS_AND_CONDITIONS,
  [Role.MosupPromote]: Routes.SELECTOR_SDK,
  [Role.MosupUpload]: Routes.SELECTOR_SDK,
};

function HomeScreen() {
  const navigate = useNavigate();
  const role = useAppSelector(selectSelectedRole);

  useEffect(() => {
    if (role) {
      navigate(defaultRoutes[role]);
    }
  }, [navigate, role]);

  return null;
}

export default HomeScreen;
