import React from 'react';
import { Box, useColorModeValue } from '@chakra-ui/react';
import Sidebar from './Sidebar';
import Navbar from './Navbar';
import Notification from '../components/notification/Notification';

type LayoutProps = {
  children: React.ReactNode;
};

function Layout({ children }: LayoutProps) {
  return (
    <Box minH="100vh" bg={useColorModeValue('gray.100', 'gray.900')}>
      <Notification />
      <Sidebar />
      <Navbar />
      <Box ml={60} p="4">
        {children}
      </Box>
    </Box>
  );
}

export default Layout;
