import type { Notification } from '../store/notificationSlice';

const DURATION_MS = null; // Do not auto-close notifications
const IS_CLOSABLE = true;
const POSITION = 'bottom';

const randomId = () => Math.floor(Math.random() * Date.now());

const createNotification = (status: 'success' | 'error', title: string): Notification => ({
  id: randomId().toString(),
  title,
  status,
  duration: DURATION_MS,
  isClosable: IS_CLOSABLE,
  position: POSITION,
});

/**
 * Create a success notification
 * @param title the notification title
 */
export function successNotification(title: string): Notification {
  return createNotification('success', title);
}

/**
 * Create an error notification
 * @param title the notification title
 */
export function errorNotification(title: string): Notification {
  return createNotification('error', title);
}
