enum Role {
  OperationManager = 'operation_manager',
  FirstLineSupportAgent = 'first_line_support_agent',
  SecondLineSupportAgent = 'second_line_support_agent',
  FraudOfficer = 'fraud_officer',
  MosupUpload = 'mosup_upload',
  MosupPromote = 'mosup_promote',
}
export default Role;

// Sort the roles by the order in which they are defined in the enum
const VALUES = Object.values(Role);
export const byEnumOrder = (role1: Role, role2: Role) => VALUES.indexOf(role1) - VALUES.indexOf(role2);
