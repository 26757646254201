import {
  AuthorizationRequestResponse,
  AuthorizationServiceConfiguration,
  FetchRequestor,
  TokenRequest,
  TokenResponse,
} from '@openid/appauth';
import Logger from 'logger';
import ExpiryMap from 'expiry-map';
import authConfig from './authConfig';
import * as AuthFactory from './authFactory';
import { buildLogoutUrl } from './authUtils';

const configMap = new ExpiryMap<string, AuthorizationServiceConfiguration>(60 * 60 * 1000); // 1 hour

const fetchConfig = async (): Promise<AuthorizationServiceConfiguration> => {
  const { issuerUrl } = authConfig;
  const cachedConfig = configMap.get(authConfig.issuerUrl);

  if (cachedConfig) {
    Logger.debug('Retrieved OpenID Connect configuration from cache: ', cachedConfig);
    return cachedConfig;
  }

  try {
    const config = await AuthorizationServiceConfiguration.fetchFromIssuer(issuerUrl, new FetchRequestor());
    configMap.set(issuerUrl, config);
    Logger.debug('Retrieved OpenID Connect configuration: ', config);
    return config;
  } catch (e) {
    throw Error(`Failed to fetch OpenID Connect configuration: ${JSON.stringify(e)}`);
  }
};

const performTokenRequest = async (tokenRequest: TokenRequest) => {
  const tokenHandler = AuthFactory.tokenHandler();
  const config = await fetchConfig();
  try {
    const tokenResponse = await tokenHandler.performTokenRequest(config, tokenRequest);
    Logger.debug('Token response ', tokenResponse);
    return tokenResponse;
  } catch (e) {
    throw new Error(`Token request failed: ${JSON.stringify(e)}`);
  }
};

export const startAuthentication = async (): Promise<void> => {
  const config = await fetchConfig();
  const request = AuthFactory.authorizationCodeRequest();
  AuthFactory.redirectRequestHandler.performAuthorizationRequest(config, request);
};

export const completeAuthentication = async (): Promise<TokenResponse | null> =>
  /*
   * Trick to avoid relying on the build-in notifier, which doesn't return if there's no ongoing authorization request.
   */
  AuthFactory.redirectRequestHandler
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    .completeAuthorizationRequest()
    .then((authorizationResponse: AuthorizationRequestResponse | null) => {
      if (!authorizationResponse) {
        return null;
      }

      Logger.info('Completing authorization request: ', authorizationResponse);
      if (authorizationResponse.error) {
        throw new Error(`Authorization request failed: ${JSON.stringify(authorizationResponse.error)}`);
      }

      if (!authorizationResponse.response) {
        throw new Error('Missing authorization response');
      }

      const tokenRequest = AuthFactory.tokenRequest(authorizationResponse.request, authorizationResponse.response);
      return performTokenRequest(tokenRequest);
    });

export const logout = async () => {
  for (let i = 0; i < localStorage.length; i += 1) {
    const key = localStorage.key(i);
    if (key && key.includes('appauth')) {
      Logger.debug(`Removing '${key}' from local storage`);
      localStorage.removeItem(key);
    }
  }
  document.cookie = '';
  document.location = buildLogoutUrl(authConfig);
};

export const refreshToken = async (token: string) => {
  const tokenRequest = AuthFactory.refreshTokenRequest(token);
  return performTokenRequest(tokenRequest);
};
