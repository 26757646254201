/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import App from 'App';
import { ChakraProvider } from '@chakra-ui/react';
import { IntlProvider } from 'react-intl';
import { Provider } from 'react-redux';
import messagesEn from './i18n/en';
import theme from './theme';
import { setupStore } from './store/store';

const container = document.getElementById('root');
const root = createRoot(container!);
const store = setupStore();

root.render(
  <StrictMode>
    <IntlProvider messages={messagesEn} locale="en" defaultLocale="en">
      <ChakraProvider theme={theme}>
        <Provider store={store}>
          <App />
        </Provider>
      </ChakraProvider>
    </IntlProvider>
  </StrictMode>,
);
