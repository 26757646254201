import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk, RootState } from './types';
import { GetBatchUseRevocationJobResponse } from '../types/GetBatchUseRevocationJobResponse';
import * as userService from '../service/userService';
import { getOrRefreshAccessToken } from './authenticationSlice';
import { displayErrorNotification, displaySuccessNotification } from './notificationSlice';

export enum BatchUserRevocationStatus {
  Idle = 'Idle',
  Loading = 'Loading',
  Success = 'Success',
  Error = 'Error',
}

export interface BatchUserRevocationState {
  job: GetBatchUseRevocationJobResponse | undefined;
  status: BatchUserRevocationStatus;
  error: string | undefined;
  isUploading: boolean;
}

export const initialState: BatchUserRevocationState = {
  job: undefined,
  status: BatchUserRevocationStatus.Idle,
  error: undefined,
  isUploading: false,
};

/* Reducer */
export const batchUserRevocationSlice = createSlice({
  name: 'batchUserRevocation',
  initialState,
  reducers: {
    // get
    getBatchUserRevocationJobStart: (state: BatchUserRevocationState) => {
      state.status = BatchUserRevocationStatus.Loading;
    },
    getBatchUserRevocationJobSuccess: (
      state: BatchUserRevocationState,
      action: PayloadAction<GetBatchUseRevocationJobResponse | undefined>,
    ) => {
      state.status = BatchUserRevocationStatus.Success;
      state.job = action.payload;
    },
    getBatchUserRevocationJobError: (state: BatchUserRevocationState, action: PayloadAction<string>) => {
      state.status = BatchUserRevocationStatus.Error;
      state.error = action.payload;
    },
    // upload
    createBatchUserRevocationJobStart: (state: BatchUserRevocationState) => {
      state.isUploading = true;
    },
    createBatchUserRevocationJobEnd: (state: BatchUserRevocationState) => {
      state.isUploading = false;
    },
  },
});

export const {
  getBatchUserRevocationJobStart,
  getBatchUserRevocationJobSuccess,
  getBatchUserRevocationJobError,
  createBatchUserRevocationJobStart,
  createBatchUserRevocationJobEnd,
} = batchUserRevocationSlice.actions;

/* Selectors */
export const selectBatchUserRevocationStatus = (state: RootState) => state.batchUserRevocation.status;
export const selectBatchUserRevocationJob = (state: RootState) => state.batchUserRevocation.job;
export const selectBatchUserRevocationError = (state: RootState) => state.batchUserRevocation.error;
export const selectBatchUserRevocationUploading = (state: RootState) => state.batchUserRevocation.isUploading;

/* Thunk */
export const getBatchUserRevocationJob = (): AppThunk => async (dispatch, getState) => {
  if (selectBatchUserRevocationStatus(getState()) === BatchUserRevocationStatus.Loading) {
    return;
  }

  try {
    dispatch(getBatchUserRevocationJobStart());
    const accessToken = await getOrRefreshAccessToken(dispatch, getState);
    const response = await userService.getBatchUserRevocationJob(accessToken);
    dispatch(getBatchUserRevocationJobSuccess(response));
  } catch (e) {
    dispatch(getBatchUserRevocationJobError(e.message));
  }
};

export const createBatchUserRevocationJob =
  ({
    file,
    successMessage,
    errorMessage,
  }: {
    file: File;
    successMessage: () => string;
    errorMessage: (e: Error) => string;
  }): AppThunk =>
  async (dispatch, getState) => {
    if (selectBatchUserRevocationUploading(getState())) {
      return;
    }

    try {
      dispatch(createBatchUserRevocationJobStart());
      const accessToken = await getOrRefreshAccessToken(dispatch, getState);
      await userService.createBatchUserRevocationJob(accessToken, file);
      dispatch(displaySuccessNotification(successMessage()));
    } catch (e) {
      dispatch(displayErrorNotification(errorMessage(e)));
    } finally {
      dispatch(createBatchUserRevocationJobEnd());
    }
  };
