import { combineReducers, configureStore, PreloadedState } from '@reduxjs/toolkit';
import thunkMiddleware from 'redux-thunk';
import { searchUserSlice } from './searchUserSlice';
import { authenticationSlice } from './authenticationSlice';
import type { RootState } from './types';
import { userSlice } from './userSlice';
import { notificationSlice } from './notificationSlice';
import { batchUserRevocationSlice } from './batchUserRevocationSlice';
import { searchLogSlice } from './searchLogSlice';
import { searchOtpSlice } from './searchOtpSlice';
import { masStatusSlice } from './masStatusSlice';
import { countUsersSlice } from './countUsersSlice';
import { blacklistSlice } from './blacklistSlice';
import { selectorSdkSlice } from './selectorSdkSlice';
import { whitelistSlice } from './whitelistSlice';

export const version = 1;

export const rootReducer = combineReducers({
  searchUser: searchUserSlice.reducer,
  searchLog: searchLogSlice.reducer,
  searchOtp: searchOtpSlice.reducer,
  user: userSlice.reducer,
  batchUserRevocation: batchUserRevocationSlice.reducer,
  masStatus: masStatusSlice.reducer,
  authentication: authenticationSlice.reducer,
  notification: notificationSlice.reducer,
  userCount: countUsersSlice.reducer,
  blacklist: blacklistSlice.reducer,
  selectorSdk: selectorSdkSlice.reducer,
  whitelistSlice: whitelistSlice.reducer,
});

export function setupStore(preloadedState?: PreloadedState<RootState>) {
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({}).concat(thunkMiddleware),
    preloadedState,
  });
}
