import sortBy from 'lodash.sortby';
import SearchLogRequest from '../types/SearchLogRequest';
import SearchLogResponse from '../types/SearchLogResponse';
import { post } from './masClient';
import LogLine from '../types/LogLine';
import SortDirection from '../types/SortDirection';
import SortLogRequest from '../types/SortLogRequest';

export const searchLog = async (searchLogRequest: SearchLogRequest, accessToken: string): Promise<SearchLogResponse> =>
  post(`/v1/admin/logs`, accessToken, searchLogRequest);

export const sortLog = (logs: LogLine[], sortRequest: SortLogRequest) => {
  const sortedLogs = sortBy([...logs], (log) => log[sortRequest.sortKey]);

  if (sortRequest.sortDirection === SortDirection.DESC) {
    return sortedLogs.reverse();
  }

  return sortedLogs;
};
