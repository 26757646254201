import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { errorNotification, successNotification } from '../service/notificationService';
import type { RootState } from './types';

export type Notification = {
  id: string;
  title: string;
  status: 'success' | 'error';
  duration: number | null;
  isClosable: boolean;
  position: 'top' | 'bottom';
};

export interface NotificationState {
  notifications: Notification[];
}

export const initialState: NotificationState = {
  notifications: [],
};

/* Reducer */
export const notificationSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    displaySuccessNotification: (state: NotificationState, action: PayloadAction<string>) => {
      const notification = successNotification(action.payload);
      state.notifications.push(notification);
    },
    displayErrorNotification: (state: NotificationState, action: PayloadAction<string>) => {
      const notification = errorNotification(action.payload);
      state.notifications.push(notification);
    },
    deleteNotification: (state: NotificationState, action: PayloadAction<string>) => {
      state.notifications = state.notifications.filter((notification) => notification.id !== action.payload);
    },
  },
});

export const { displaySuccessNotification, displayErrorNotification, deleteNotification } = notificationSlice.actions;

/* Selectors */
export const selectNotifications = (state: RootState) => state.notification.notifications;
