export enum MosupAssetType {
  SelectorSdkAndroid = 'SELECTOR_SDK_ANDROID',
  SelectorSdkIos = 'SELECTOR_SDK_IOS',
  Whitelist = 'WHITELIST',
}

export type SelectorSdkPlatform = MosupAssetType.SelectorSdkAndroid | MosupAssetType.SelectorSdkIos;

export type MosupCurrentAssetVersion = {
  type: MosupAssetType;
  uploadedAt: string;
  uploadedByEmail: string;
  promotedAt: string;
  promotedByEmail: string;
  comment?: string;
  url: string;
};

export type MosupStagingAssetVersion = {
  type: MosupAssetType;
  uploadedAt: string;
  uploadedByEmail: string;
  comment?: string;
  url: string;
};

export type MosupReadResponse = {
  currentVersion?: MosupCurrentAssetVersion;
  stagingVersion?: MosupStagingAssetVersion;
};

export type MosupUploadRequest = {
  comment?: string;
  file: string;
};
