import {
  AuthorizationRequest,
  AuthorizationResponse,
  BaseTokenRequestHandler,
  DefaultCrypto,
  FetchRequestor,
  GRANT_TYPE_AUTHORIZATION_CODE,
  GRANT_TYPE_REFRESH_TOKEN,
  LocalStorageBackend,
  RedirectRequestHandler,
  TokenRequest,
} from '@openid/appauth';
import NoHashQueryStringUtils from './noHashQueryStringUtils';
import authConfig from './authConfig';
import { getExtras } from './authUtils';

export const redirectRequestHandler = new RedirectRequestHandler(
  new LocalStorageBackend(),
  new NoHashQueryStringUtils(),
  window.location,
  new DefaultCrypto(),
);

export const tokenHandler = () => new BaseTokenRequestHandler(new FetchRequestor());

export const authorizationCodeRequest = () =>
  new AuthorizationRequest(
    {
      client_id: authConfig.clientId,
      redirect_uri: authConfig.redirectUri,
      scope: 'openid',
      response_type: AuthorizationRequest.RESPONSE_TYPE_CODE,
    },
    new DefaultCrypto(),
    true,
  );

export const tokenRequest = (
  authorizationRequest: AuthorizationRequest,
  authorizationResponse: AuthorizationResponse,
) =>
  new TokenRequest({
    client_id: authConfig.clientId,
    redirect_uri: authConfig.redirectUri,
    grant_type: GRANT_TYPE_AUTHORIZATION_CODE,
    code: authorizationResponse.code,
    refresh_token: undefined,
    extras: getExtras(authorizationRequest),
  });

export const refreshTokenRequest = (refreshToken: string) =>
  new TokenRequest({
    client_id: authConfig.clientId,
    redirect_uri: authConfig.redirectUri,
    grant_type: GRANT_TYPE_REFRESH_TOKEN,
    code: undefined,
    refresh_token: refreshToken,
  });
