import { useToast } from '@chakra-ui/react';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { deleteNotification, selectNotifications } from '../../store/notificationSlice';

export default function Notification() {
  const notifications = useAppSelector(selectNotifications);
  const dispatch = useAppDispatch();
  const toast = useToast();

  useEffect(() => {
    notifications
      .filter((notification) => !toast.isActive(notification.id))
      .map((notification) =>
        toast({
          id: notification.id,
          title: notification.title,
          status: notification.status,
          duration: notification.duration,
          isClosable: notification.isClosable,
          position: notification.position,
          onCloseComplete: () => dispatch(deleteNotification(notification.id)),
        }),
      );
  }, [notifications, toast, dispatch]);

  return <div />;
}
