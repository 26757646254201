import { GetUserResponse } from '../types/GetUserResponse';
import { GetBatchUseRevocationJobResponse } from '../types/GetBatchUseRevocationJobResponse';
import type { SearchUserRequest } from '../types/SearchUserRequest';
import DeviceLogoutReason from '../types/DeviceLogoutReason';
import HttpError from './HttpError';
import { get, post } from './masClient';
import { SearchUserResponse } from '../types/SearchUserResponse';
import DeviceLogoutRequest from '../types/DeviceLogoutRequest';
import AdminUserCountResponse from '../types/AdminUserCountResponse';
import { SyncWalletRequest } from '../types/SyncWalletRequest';
import { DeleteCardRequest } from '../types/DeleteCardRequest';

export const searchUsers = async (
  userSearchRequest: SearchUserRequest,
  accessToken: string,
): Promise<SearchUserResponse> => post(`/v1/admin/user-search`, accessToken, userSearchRequest);

export const getUser = async (id: string, accessToken: string): Promise<GetUserResponse> =>
  get(`/v1/admin/users/${id}`, accessToken);

export const adminCountUsers = async (accessToken: string): Promise<AdminUserCountResponse> =>
  get(`/v1/admin/users-count`, accessToken);

export const deleteUser = async (id: string, accessToken: string): Promise<void> =>
  post(`/v1/admin/users/${id}/delete`, accessToken);

export const revokeUser = async (id: string, accessToken: string): Promise<void> =>
  post(`/v1/admin/users/${id}/revoke`, accessToken);

export const unregisterPqiUser = async (id: string, accessToken: string): Promise<void> =>
  post(`/v1/admin/users/${id}/pqi-unregister`, accessToken);

export const syncWallet = async (request: SyncWalletRequest, accessToken: string): Promise<void> =>
  post(`/v1/admin/wallet/sync`, accessToken, request);

export const deleteCard = async (request: DeleteCardRequest, accessToken: string): Promise<void> =>
  post(`/v1/admin/wallet/delete-card`, accessToken, request);

export const logoutDevice = async (
  request: DeviceLogoutRequest,
  reason: DeviceLogoutReason,
  accessToken: string,
): Promise<void> => post(`/v1/admin/device/log-out?reason=${reason}`, accessToken, request);

export const getBatchUserRevocationJob = async (
  accessToken: string,
): Promise<GetBatchUseRevocationJobResponse | undefined> => {
  try {
    return await get(`/v1/admin/batch-user-revocation-job`, accessToken);
  } catch (e) {
    if (e instanceof HttpError && (e as HttpError).status === 404) {
      return undefined;
    }
    throw e;
  }
};

export const createBatchUserRevocationJob = async (accessToken: string, file: File): Promise<void> => {
  const csv = await file.text();
  return post(`/v1/admin/batch-user-revocation-job`, accessToken, {
    filename: file.name,
    csv,
  });
};
