import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk, RootState } from './types';
import * as OtpService from '../service/otpService';
import { getOrRefreshAccessToken } from './authenticationSlice';
import { displayErrorNotification } from './notificationSlice';
import GetOtpCounterResponse from '../types/GetOtpCounterResponse';
import GetOtpCounterRequest from '../types/GetOtpCounterRequest';
import ResetOtpCounterRequest from '../types/ResetOtpCounterRequest';

export enum SearchOtpStatus {
  Idle = 'Idle',
  Loading = 'Loading',
  Success = 'Success',
  Resetting = 'Resetting',
}

export interface SearchOtpState {
  status: SearchOtpStatus;
  url: string | undefined;
  counter: GetOtpCounterResponse | undefined;
}

export const initialState: SearchOtpState = {
  status: SearchOtpStatus.Idle,
  url: undefined,
  counter: undefined,
};

/* Reducer */
export const searchOtpSlice = createSlice({
  name: 'searchOtp',
  initialState,
  reducers: {
    // Get OTP counter
    getOtpCounterStart: (state: SearchOtpState, action: PayloadAction<string>) => {
      state.status = SearchOtpStatus.Loading;
      state.url = action.payload;
    },
    getOtpCounterSuccess: (state: SearchOtpState, action: PayloadAction<GetOtpCounterResponse>) => {
      state.status = SearchOtpStatus.Success;
      state.counter = action.payload;
    },
    getOtpCounterError: (state: SearchOtpState) => {
      state.status = SearchOtpStatus.Idle;
      state.counter = undefined;
    },
    // Reset OTP counter
    resetOtpCounterStart: (state: SearchOtpState) => {
      state.status = SearchOtpStatus.Resetting;
    },
    resetOtpCounterSuccess: (state: SearchOtpState) => {
      state.status = SearchOtpStatus.Success;
      if (state.counter) {
        state.counter.otpRemainingCodes = state.counter?.otpDailyCodes;
      }
    },
    resetOtpCounterError: (state: SearchOtpState) => {
      state.status = SearchOtpStatus.Success;
    },
  },
});

export const {
  getOtpCounterStart,
  getOtpCounterSuccess,
  getOtpCounterError,
  resetOtpCounterStart,
  resetOtpCounterSuccess,
  resetOtpCounterError,
} = searchOtpSlice.actions;

/* Thunk */
export const getOtpCounter =
  (getOtpCounterRequest: GetOtpCounterRequest): AppThunk =>
  async (dispatch, getState) => {
    try {
      dispatch(getOtpCounterStart(getOtpCounterRequest.url));
      const accessToken = await getOrRefreshAccessToken(dispatch, getState);
      const response = await OtpService.getOtpCounter(getOtpCounterRequest, accessToken);
      dispatch(getOtpCounterSuccess(response));
    } catch (e) {
      dispatch(displayErrorNotification(e.toString()));
      dispatch(getOtpCounterError());
    }
  };

export const resetOtpCounter =
  (resetOtpCounterRequest: ResetOtpCounterRequest): AppThunk =>
  async (dispatch, getState) => {
    try {
      dispatch(resetOtpCounterStart());
      const accessToken = await getOrRefreshAccessToken(dispatch, getState);
      await OtpService.resetOtpCounter(resetOtpCounterRequest, accessToken);
      dispatch(resetOtpCounterSuccess());
    } catch (e) {
      dispatch(displayErrorNotification(e.toString()));
      dispatch(resetOtpCounterError());
    }
  };

/* Selectors */
export const selectSearchOtpUrl = (state: RootState) => state.searchOtp.url;
export const selectSearchOtpCounter = (state: RootState) => state.searchOtp.counter;
export const selectSearchOtpStatus = (state: RootState) => state.searchOtp.status;
