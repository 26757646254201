import { Center, Heading, Text } from '@chakra-ui/react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import Labels from '../../i18n/labels';

export default function AuthGuardLoginRequired() {
  return (
    <Center
      margin="auto"
      maxW="32rem"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      textAlign="center"
      padding="30px"
      data-testid="loginRequiredMessage"
    >
      <Heading mb={4}>
        <FormattedMessage id={Labels.AUTH_REQUIRED_TITLE} />
      </Heading>
      <Text fontSize="xl">
        <FormattedMessage id={Labels.AUTH_REQUIRED_BODY} />
      </Text>
    </Center>
  );
}
