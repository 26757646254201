import { BrowserRouter, Route, Routes } from 'react-router-dom';
import React from 'react';
import AppRoute from 'routes';
import Layout from 'layout/Layout';
import AuthGuard from 'components/auth/AuthGuard';
import HomeScreen from './screens/home/HomeScreen';
import NotFoundScreen from './screens/not-found/NotFoundScreen';

const UserScreen = React.lazy(() => import('./screens/user/UserScreen'));
const SearchUserScreen = React.lazy(() => import('./screens/search-user/SearchUserScreen'));
const DeleteUsersScreen = React.lazy(() => import('./screens/batch-user-revocation/BatchUserRevocationScreen'));
const TermAndConditionScreen = React.lazy(() => import('./screens/terms-and-conditions/TermAndConditionScreen'));
const SearchLogScreen = React.lazy(() => import('./screens/search-logs/SearchLogScreen'));
const SearchOtpScreen = React.lazy(() => import('./screens/search-otp/SearchOtpScreen'));
const MasStatusScreen = React.lazy(() => import('./screens/status/MasStatusScreen'));
const BlacklistScreen = React.lazy(() => import('./screens/blacklist/BlacklistScreen'));
const SelectorSdkScreen = React.lazy(() => import('./screens/mosup/selector-sdk/SelectorSdkScreen'));
const WhitelistScreen = React.lazy(() => import('./screens/mosup/whitelist/WhitelistScreen'));
const suspense = (children: React.ReactNode) => <React.Suspense> {children} </React.Suspense>;

function App() {
  return (
    <BrowserRouter>
      <Layout>
        <AuthGuard>
          <Routes>
            <Route path={AppRoute.HOME} element={<HomeScreen />} />
            <Route path={AppRoute.SEARCH_USER} element={suspense(<SearchUserScreen />)} />
            <Route path={AppRoute.USER} element={suspense(<UserScreen />)} />
            <Route path={AppRoute.REVOKE_USERS} element={suspense(<DeleteUsersScreen />)} />
            <Route path={AppRoute.TERMS_AND_CONDITIONS} element={suspense(<TermAndConditionScreen />)} />
            <Route path={AppRoute.SEARCH_LOG} element={suspense(<SearchLogScreen />)} />
            <Route path={AppRoute.SEARCH_OTP} element={suspense(<SearchOtpScreen />)} />
            <Route path={AppRoute.STATUS} element={suspense(<MasStatusScreen />)} />
            <Route path={AppRoute.BLACKLIST} element={suspense(<BlacklistScreen />)} />
            <Route>
              <Route path={AppRoute.SELECTOR_SDK} element={suspense(<SelectorSdkScreen />)} />
              <Route path={AppRoute.WHITELIST} element={suspense(<WhitelistScreen />)} />
            </Route>
            <Route path="*" element={<NotFoundScreen />} />
          </Routes>
        </AuthGuard>
      </Layout>
    </BrowserRouter>
  );
}

export default App;
