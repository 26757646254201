enum SortDirection {
  ASC = 'ASC',
  DESC = 'DESC',
}

export const switchSortDirection = (direction: SortDirection) => {
  if (direction === SortDirection.ASC) {
    return SortDirection.DESC;
  }
  return SortDirection.ASC;
};

export default SortDirection;
