import { post } from './masClient';
import GetOtpCounterRequest from '../types/GetOtpCounterRequest';
import GetOtpCounterResponse from '../types/GetOtpCounterResponse';
import ResetOtpCounterRequest from '../types/ResetOtpCounterRequest';
import errorHandler from './errorHandler';

export const getOtpCounter = async (
  request: GetOtpCounterRequest,
  accessToken: string,
): Promise<GetOtpCounterResponse> => post(`/v1/admin/otp-counter`, accessToken, request).catch(errorHandler);

export const resetOtpCounter = async (request: ResetOtpCounterRequest, accessToken: string): Promise<void> =>
  post(`/v1/admin/reset-otp-counter`, accessToken, request).catch(errorHandler);
