import HttpError from './HttpError';
import BusinessRuleErrorResponse from '../types/BusinessRuleErrorResponse';

const tryParse = (str: string): BusinessRuleErrorResponse | undefined => {
  try {
    return JSON.parse(str) as BusinessRuleErrorResponse;
  } catch (e) {
    return undefined;
  }
};

const extractFirstViolation = (json: BusinessRuleErrorResponse) => json?.violations?.[0];

export default (e: Error) => {
  if (!(e instanceof HttpError) || e.status !== 400) {
    return Promise.reject(e);
  }

  const json = tryParse(e.message);
  if (!json) {
    return Promise.reject(e);
  }

  const violation = extractFirstViolation(json);
  if (violation) {
    return Promise.reject(Error(JSON.stringify(violation)));
  }
  return Promise.reject(e);
};
