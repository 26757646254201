import { Center, Spinner, Text } from '@chakra-ui/react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import Labels from '../../i18n/labels';

export default function AuthGuardSpinner() {
  return (
    <Center padding={50} flex="auto" flexDir="column" data-testid="authenticationSpinner">
      <Spinner size="xl" />
      <Text mt={6} size="md">
        <FormattedMessage id={Labels.AUTH_LOADING} />
      </Text>
    </Center>
  );
}
