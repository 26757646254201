import React from 'react';
import { Flex, FlexProps, Icon, Link } from '@chakra-ui/react';
import { IconType } from 'react-icons';
import { NavLink } from 'react-router-dom';

interface SidebarItemProps extends FlexProps {
  icon: IconType;
  to: string;
  children: React.ReactNode;
}

function SidebarItem({ icon, to, children }: SidebarItemProps) {
  return (
    <Link
      as={NavLink}
      to={to}
      style={{ textDecoration: 'none' }}
      _focus={{ boxShadow: 'none' }}
      _activeLink={{
        fontWeight: 'bold',
        color: 'teal',
      }}
    >
      <Flex
        align="center"
        p="2"
        mx="4"
        borderRadius="lg"
        role="group"
        cursor="pointer"
        _hover={{ bg: 'teal.400', color: 'white' }}
      >
        <Icon mr="4" fontSize="16" _groupHover={{ color: 'white' }} as={icon} />
        {children}
      </Flex>
    </Link>
  );
}

export default SidebarItem;
