export class Logger {
  private name: string;

  constructor(name: string) {
    this.name = name;
  }

  public debug(msg: string, ...supportingDetails: unknown[]) {
    this.emitLog('debug', msg, supportingDetails);
  }

  public info(msg: string, ...supportingDetails: unknown[]) {
    this.emitLog('info', msg, supportingDetails);
  }

  public warn(msg: string, ...supportingDetails: unknown[]) {
    this.emitLog('warn', msg, supportingDetails);
  }

  public error(msg: string, ...supportingDetails: unknown[]) {
    this.emitLog('error', msg, supportingDetails);
  }

  private emitLog(level: 'debug' | 'info' | 'warn' | 'error', msg: string, supportingDetails: unknown[]) {
    if (supportingDetails.length > 0) {
      /* eslint-disable no-console */
      console[level](`[${this.name}] ${msg}`, supportingDetails);
    } else {
      /* eslint-disable no-console */
      console[level](`[${this.name}] ${msg}`);
    }
  }
}

export default new Logger('AdminTool');
