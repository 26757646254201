import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk, RootState } from './types';
import * as logService from '../service/logService';
import { getOrRefreshAccessToken } from './authenticationSlice';
import type SearchLogResponse from '../types/SearchLogResponse';
import type SearchLogRequest from '../types/SearchLogRequest';
import LogLine from '../types/LogLine';
import { displayErrorNotification } from './notificationSlice';
import SortLogRequest from '../types/SortLogRequest';
import SortDirection from '../types/SortDirection';

export enum SearchLogStatus {
  Idle = 'Idle',
  Loading = 'Loading',
  Success = 'Success',
  Error = 'Error',
}

export interface SearchLogState {
  logs: LogLine[];
  hasMore: boolean;
  status: SearchLogStatus;
  sortRequest: SortLogRequest;
}

export const initialState: SearchLogState = {
  logs: [],
  hasMore: false,
  status: SearchLogStatus.Idle,
  sortRequest: {
    sortDirection: SortDirection.DESC,
    sortKey: 'timestamp',
  },
};

/* Reducer */
export const searchLogSlice = createSlice({
  name: 'searchLog',
  initialState,
  reducers: {
    // get
    searchLogStart: (state: SearchLogState) => {
      state.status = SearchLogStatus.Loading;
    },
    searchLogSuccess: (state: SearchLogState, action: PayloadAction<SearchLogResponse>) => {
      state.status = SearchLogStatus.Success;
      state.logs = action.payload.logs;
      state.hasMore = action.payload.hasMoreResults;
    },
    searchLogError: (state: SearchLogState) => {
      state.status = SearchLogStatus.Error;
      state.logs = [];
      state.hasMore = false;
    },
    // sort
    sortLog: (state: SearchLogState, action: PayloadAction<SortLogRequest>) => {
      state.sortRequest = action.payload;
    },
  },
});

export const { searchLogStart, searchLogSuccess, searchLogError, sortLog } = searchLogSlice.actions;

/* Selectors */
export const selectSearchLogStatus = (state: RootState) => state.searchLog.status;
export const selectSearchLogLogs = createSelector(
  [(state: RootState) => state.searchLog.logs, (state: RootState) => state.searchLog.sortRequest],
  (logs, sortRequest) => logService.sortLog(logs, sortRequest),
);
export const selectSearchLogHasMore = (state: RootState) => state.searchLog.hasMore;
export const selectSearchLogSort = (state: RootState) => state.searchLog.sortRequest;

/* Thunk */
export const searchLog =
  (searchLogRequest: SearchLogRequest): AppThunk =>
  async (dispatch, getState) => {
    if (selectSearchLogStatus(getState()) === SearchLogStatus.Loading) {
      return;
    }

    try {
      dispatch(searchLogStart());
      const accessToken = await getOrRefreshAccessToken(dispatch, getState);
      const response = await logService.searchLog(searchLogRequest, accessToken);
      dispatch(searchLogSuccess(response));
    } catch (e) {
      dispatch(displayErrorNotification(e.toString()));
      dispatch(searchLogError());
    }
  };
