import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk, RootState } from './types';
import { getOrRefreshAccessToken } from './authenticationSlice';
import { displayErrorNotification, displaySuccessNotification } from './notificationSlice';
import {
  MosupAssetType,
  MosupCurrentAssetVersion,
  MosupReadResponse,
  MosupStagingAssetVersion,
  MosupUploadRequest,
} from '../types/Mosup';
import * as MosupService from '../service/mosupService';

export enum WhitelistStatus {
  Idle = 'Idle',
  Loading = 'Loading',
  Success = 'Success',
}

export interface WhitelistState {
  status: WhitelistStatus;
  isUploading: boolean;
  isPromoting: boolean;
  currentVersion: MosupCurrentAssetVersion | undefined;
  stagingVersion: MosupStagingAssetVersion | undefined;
}

export const initialState: WhitelistState = {
  status: WhitelistStatus.Idle,
  isUploading: false,
  isPromoting: false,
  currentVersion: undefined,
  stagingVersion: undefined,
};

/* Reducer */
export const whitelistSlice = createSlice({
  name: 'whitelist',
  initialState,
  reducers: {
    // Read
    readWhitelistStart: (state: WhitelistState) => {
      state.status = WhitelistStatus.Loading;
    },
    readWhitelistSuccess: (state: WhitelistState, action: PayloadAction<MosupReadResponse>) => {
      state.status = WhitelistStatus.Success;
      state.stagingVersion = action.payload.stagingVersion;
      state.currentVersion = action.payload.currentVersion;
    },
    readWhitelistError: (state: WhitelistState) => {
      state.status = WhitelistStatus.Idle;
      state.stagingVersion = undefined;
      state.currentVersion = undefined;
    },
    // Upload
    uploadWhitelistStart: (state: WhitelistState) => {
      state.isUploading = true;
    },
    uploadWhitelistSuccess: (state: WhitelistState) => {
      state.isUploading = false;
    },
    uploadWhitelistError: (state: WhitelistState) => {
      state.isUploading = false;
    },
    // Promote
    promoteWhitelistStart: (state: WhitelistState) => {
      state.isPromoting = true;
    },
    promoteWhitelistSuccess: (state: WhitelistState) => {
      state.isPromoting = false;
    },
    promoteWhitelistError: (state: WhitelistState) => {
      state.isPromoting = false;
    },
  },
});

export const {
  readWhitelistStart,
  readWhitelistSuccess,
  readWhitelistError,
  uploadWhitelistStart,
  uploadWhitelistSuccess,
  uploadWhitelistError,
  promoteWhitelistStart,
  promoteWhitelistSuccess,
  promoteWhitelistError,
} = whitelistSlice.actions;

/* Selectors */
export const selectWhitelistStatus = (state: RootState) => state.whitelistSlice.status;
export const selectWhitelistCurrentVersion = (state: RootState) => state.whitelistSlice.currentVersion;
export const selectWhitelistStagingVersion = (state: RootState) => state.whitelistSlice.stagingVersion;
export const selectWhitelistPromoting = (state: RootState) => state.whitelistSlice.isPromoting;
export const selectWhitelistUploading = (state: RootState) => state.whitelistSlice.isUploading;

/* Thunk */
export const readWhitelistVersions = (): AppThunk => async (dispatch, getState) => {
  try {
    if (selectWhitelistStatus(getState()) === WhitelistStatus.Loading) {
      return;
    }
    dispatch(readWhitelistStart());
    const accessToken = await getOrRefreshAccessToken(dispatch, getState);
    const response = await MosupService.read(MosupAssetType.Whitelist, accessToken);
    dispatch(readWhitelistSuccess(response));
  } catch (e) {
    dispatch(displayErrorNotification(e.toString()));
    dispatch(readWhitelistError());
  }
};

export const uploadWhitelistVersion =
  ({
    uploadRequest,
    successMessage,
    errorMessage,
  }: {
    uploadRequest: MosupUploadRequest;
    successMessage: () => string;
    errorMessage: (e: Error) => string;
  }): AppThunk =>
  async (dispatch, getState) => {
    try {
      dispatch(uploadWhitelistStart());
      const accessToken = await getOrRefreshAccessToken(dispatch, getState);
      await MosupService.upload(MosupAssetType.Whitelist, uploadRequest, accessToken);
      dispatch(uploadWhitelistSuccess());
      dispatch(displaySuccessNotification(successMessage()));
      dispatch(readWhitelistVersions()); // refresh
    } catch (e) {
      dispatch(displayErrorNotification(errorMessage(e)));
      dispatch(uploadWhitelistError());
    }
  };

export const promoteWhitelistVersion =
  ({ successMessage, errorMessage }: { successMessage: () => string; errorMessage: (e: Error) => string }): AppThunk =>
  async (dispatch, getState) => {
    try {
      dispatch(promoteWhitelistStart());
      const accessToken = await getOrRefreshAccessToken(dispatch, getState);
      await MosupService.promote(MosupAssetType.Whitelist, accessToken);
      dispatch(promoteWhitelistSuccess());
      dispatch(displaySuccessNotification(successMessage()));
      dispatch(readWhitelistVersions()); // refresh
    } catch (e) {
      dispatch(displayErrorNotification(errorMessage(e)));
      dispatch(promoteWhitelistError());
    }
  };
