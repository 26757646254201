import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk, RootState } from './types';
import * as BlacklistService from '../service/blacklistService';
import { getOrRefreshAccessToken } from './authenticationSlice';
import { displayErrorNotification, displaySuccessNotification } from './notificationSlice';

import {
  AdminDeleteBlacklistEntryRequest,
  AdminSearchBlacklistRequest,
  AdminSearchBlacklistResponse,
} from '../types/Blacklist';

export enum BlacklistStatus {
  Idle = 'Idle',
  Loading = 'Loading',
  Success = 'Success',
  Deleting = 'Deleting',
}

export interface BlacklistState {
  status: BlacklistStatus;
  searchRequest: AdminSearchBlacklistRequest | undefined;
  searchResponse: AdminSearchBlacklistResponse | undefined;
}

export const initialState: BlacklistState = {
  status: BlacklistStatus.Idle,
  searchRequest: undefined,
  searchResponse: undefined,
};

/* Reducer */
export const blacklistSlice = createSlice({
  name: 'blacklist',
  initialState,
  reducers: {
    // Search blacklist
    searchBlacklistStart: (state: BlacklistState, action: PayloadAction<AdminSearchBlacklistRequest>) => {
      state.status = BlacklistStatus.Loading;
      state.searchRequest = action.payload;
    },
    searchBlacklistSuccess: (state: BlacklistState, action: PayloadAction<AdminSearchBlacklistResponse>) => {
      state.status = BlacklistStatus.Success;
      state.searchResponse = action.payload;
    },
    searchBlacklistError: (state: BlacklistState) => {
      state.status = BlacklistStatus.Idle;
      state.searchResponse = undefined;
    },
    // Delete blacklist entry
    deleteBlacklistEntryStart: (state: BlacklistState) => {
      state.status = BlacklistStatus.Deleting;
    },
    deleteBlacklistEntrySuccess: (state: BlacklistState) => {
      state.status = BlacklistStatus.Idle;
    },
    deleteBlacklistEntryError: (state: BlacklistState) => {
      state.status = BlacklistStatus.Success;
    },
  },
});

export const {
  searchBlacklistStart,
  searchBlacklistSuccess,
  searchBlacklistError,
  deleteBlacklistEntryStart,
  deleteBlacklistEntrySuccess,
  deleteBlacklistEntryError,
} = blacklistSlice.actions;

/* Thunk */
export const searchBlacklist =
  (searchBlacklistRequest: AdminSearchBlacklistRequest): AppThunk =>
  async (dispatch, getState) => {
    try {
      dispatch(searchBlacklistStart(searchBlacklistRequest));
      const accessToken = await getOrRefreshAccessToken(dispatch, getState);
      const response = await BlacklistService.searchBlacklist(searchBlacklistRequest, accessToken);
      dispatch(searchBlacklistSuccess(response));
    } catch (e) {
      dispatch(displayErrorNotification(e.toString()));
      dispatch(searchBlacklistError());
    }
  };

export const deleteBlacklistEntry =
  ({
    deleteBlacklistEntryRequest,
    successMessage,
    errorMessage,
  }: {
    deleteBlacklistEntryRequest: AdminDeleteBlacklistEntryRequest;
    successMessage: () => string;
    errorMessage: (e: Error) => string;
  }): AppThunk =>
  async (dispatch, getState) => {
    try {
      dispatch(deleteBlacklistEntryStart());
      const accessToken = await getOrRefreshAccessToken(dispatch, getState);
      await BlacklistService.deleteBlacklistEntry(deleteBlacklistEntryRequest, accessToken);
      dispatch(deleteBlacklistEntrySuccess());
      dispatch(displaySuccessNotification(successMessage()));
      // Refresh search results
      const refreshBlacklistRequest = getState().blacklist.searchRequest;
      if (refreshBlacklistRequest) {
        dispatch(searchBlacklist(refreshBlacklistRequest));
      }
    } catch (e) {
      dispatch(displayErrorNotification(errorMessage(e)));
      dispatch(deleteBlacklistEntryError());
    }
  };

/* Selectors */
export const selectBlacklistSearchRequest = (state: RootState) => state.blacklist.searchRequest;
export const selectBlacklistSearchResponse = (state: RootState) => state.blacklist.searchResponse;
export const selectBlacklistStatus = (state: RootState) => state.blacklist.status;
