import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk, RootState } from './types';
import * as userService from '../service/userService';
import { GetUserResponse } from '../types/GetUserResponse';
import { displayErrorNotification, displaySuccessNotification } from './notificationSlice';
import { getOrRefreshAccessToken } from './authenticationSlice';
import DeviceLogoutReason from '../types/DeviceLogoutReason';

export enum UserStatus {
  Idle = 'Idle',
  Loading = 'Loading',
  Success = 'Success',
  Error = 'Error',
}

export interface UserSlice {
  status: UserStatus;
  getUserResponse: GetUserResponse | undefined;
  error: string | undefined;
  isDeleting: boolean;
  isRevoking: boolean;
  isSyncingWallet: boolean;
  isUnregisteringPqiUser: boolean;
  loggingOutDevices: Record<string, boolean>;
  deletingCards: Record<string, boolean>;
}

export const initialState: UserSlice = {
  status: UserStatus.Idle,
  getUserResponse: undefined,
  error: undefined,
  isDeleting: false,
  isRevoking: false,
  isSyncingWallet: false,
  isUnregisteringPqiUser: false,
  loggingOutDevices: {},
  deletingCards: {},
};

/* Reducer */
export const userSlice = createSlice({
  name: 'getUser',
  initialState,
  reducers: {
    // Get
    getUserStart: (state: UserSlice) => {
      state.status = UserStatus.Loading;
      state.getUserResponse = undefined;
    },
    getUserSuccess: (state: UserSlice, action: PayloadAction<GetUserResponse>) => {
      state.status = UserStatus.Success;
      state.getUserResponse = action.payload;
    },
    getUserError: (state: UserSlice, action: PayloadAction<string>) => {
      state.status = UserStatus.Error;
      state.error = action.payload;
    },
    // Delete
    deleteUserStart: (state: UserSlice) => {
      state.isDeleting = true;
    },
    deleteUserEnd: (state: UserSlice) => {
      state.isDeleting = false;
    },
    // Revoke
    revokeUserStart: (state: UserSlice) => {
      state.isRevoking = true;
    },
    revokeUserEnd: (state: UserSlice) => {
      state.isRevoking = false;
    },
    // Device logout
    logoutDeviceStart: (state: UserSlice, action: PayloadAction<string>) => {
      state.loggingOutDevices[action.payload] = true;
    },
    logoutDeviceEnd: (state: UserSlice, action: PayloadAction<string>) => {
      delete state.loggingOutDevices[action.payload];
    },
    // Sync wallet
    syncWalletStart: (state: UserSlice) => {
      state.isSyncingWallet = true;
    },
    syncWalletEnd: (state: UserSlice) => {
      state.isSyncingWallet = false;
    },
    // Delete card
    deleteCardStart: (state: UserSlice, action: PayloadAction<string>) => {
      state.deletingCards[action.payload] = true;
    },
    deleteCardEnd: (state: UserSlice, action: PayloadAction<string>) => {
      delete state.deletingCards[action.payload];
    },
    // PQI unregister
    unregisterPqiUserStart: (state: UserSlice) => {
      state.isUnregisteringPqiUser = true;
    },
    unregisterPqiUserEnd: (state: UserSlice) => {
      state.isUnregisteringPqiUser = false;
    },
  },
});

export const {
  getUserStart,
  getUserSuccess,
  getUserError,
  deleteUserStart,
  deleteUserEnd,
  revokeUserStart,
  revokeUserEnd,
  logoutDeviceStart,
  logoutDeviceEnd,
  syncWalletStart,
  syncWalletEnd,
  deleteCardStart,
  deleteCardEnd,
  unregisterPqiUserStart,
  unregisterPqiUserEnd,
} = userSlice.actions;

/* Selectors */
export const selectUserStatus = (state: RootState) => state.user.status;
export const selectUserId = (state: RootState) => state.user.getUserResponse?.user.bpcAccountId;
export const selectUserResponse = (state: RootState) => state.user.getUserResponse;
export const selectUserError = (state: RootState) => state.user.error;
export const selectIsDeletingUser = (state: RootState) => state.user.isDeleting;
export const selectIsRevokingUser = (state: RootState) => state.user.isRevoking;
export const selectIsDeviceLoggingOut = (deviceId: string) => (state: RootState) =>
  state.user.loggingOutDevices[deviceId];
export const isSyncingWallet = (state: RootState) => state.user.isSyncingWallet;
export const isDeletingCard = (cardId: string) => (state: RootState) => state.user.deletingCards[cardId];
export const isUnregisteringPqiUser = (state: RootState) => state.user.isUnregisteringPqiUser;

/* Thunk */
export const getUser =
  (id: string): AppThunk =>
  async (dispatch, getState) => {
    if (selectUserStatus(getState()) === UserStatus.Loading) {
      return;
    }

    try {
      dispatch(getUserStart());
      const accessToken = await getOrRefreshAccessToken(dispatch, getState);
      const getUserResponse = await userService.getUser(id, accessToken);
      dispatch(getUserSuccess(getUserResponse));
    } catch (e) {
      dispatch(getUserError(e.message));
    }
  };

export const deleteUser =
  ({
    userId,
    successMessage,
    errorMessage,
  }: {
    userId: string;
    successMessage: () => string;
    errorMessage: (e: Error) => string;
  }): AppThunk =>
  async (dispatch, getState) => {
    if (selectIsRevokingUser(getState())) {
      return;
    }

    try {
      dispatch(deleteUserStart());
      const accessToken = await getOrRefreshAccessToken(dispatch, getState);
      await userService.deleteUser(userId, accessToken);
      dispatch(displaySuccessNotification(successMessage()));
    } catch (e) {
      dispatch(displayErrorNotification(errorMessage(e)));
    } finally {
      dispatch(deleteUserEnd());
    }
  };

export const revokeUser =
  ({
    userId,
    successMessage,
    errorMessage,
  }: {
    userId: string;
    successMessage: () => string;
    errorMessage: (e: Error) => string;
  }): AppThunk =>
  async (dispatch, getState) => {
    if (selectIsDeletingUser(getState())) {
      return;
    }

    try {
      dispatch(revokeUserStart());
      const accessToken = await getOrRefreshAccessToken(dispatch, getState);
      await userService.revokeUser(userId, accessToken);
      dispatch(displaySuccessNotification(successMessage()));
    } catch (e) {
      dispatch(displayErrorNotification(errorMessage(e)));
    } finally {
      dispatch(revokeUserEnd());
    }
  };

export const logoutDevice =
  ({
    userId,
    deviceId,
    reason,
    successMessage,
    errorMessage,
  }: {
    userId: string;
    deviceId: string;
    reason: DeviceLogoutReason;
    successMessage: () => string;
    errorMessage: (e: Error) => string;
  }): AppThunk =>
  async (dispatch, getState) => {
    if (selectIsDeviceLoggingOut(deviceId)(getState())) {
      return;
    }

    try {
      dispatch(logoutDeviceStart(deviceId));
      const accessToken = await getOrRefreshAccessToken(dispatch, getState);
      await userService.logoutDevice(
        {
          userId,
          deviceId,
        },
        reason,
        accessToken,
      );
      dispatch(displaySuccessNotification(successMessage()));

      // refresh the user data
      const userid = selectUserResponse(getState())?.user.bpcAccountId;
      if (userid) {
        dispatch(getUser(userid));
      }
    } catch (e) {
      dispatch(displayErrorNotification(errorMessage(e)));
    } finally {
      dispatch(logoutDeviceEnd(deviceId));
    }
  };

export const syncWallet =
  ({
    userId,
    successMessage,
    errorMessage,
  }: {
    userId: string;
    successMessage: () => string;
    errorMessage: (e: Error) => string;
  }): AppThunk =>
  async (dispatch, getState) => {
    if (isSyncingWallet(getState())) {
      return;
    }

    try {
      dispatch(syncWalletStart());
      const accessToken = await getOrRefreshAccessToken(dispatch, getState);
      await userService.syncWallet(
        {
          userId,
        },
        accessToken,
      );
      dispatch(displaySuccessNotification(successMessage()));

      // refresh the user data
      const userid = selectUserResponse(getState())?.user.bpcAccountId;
      if (userid) {
        dispatch(getUser(userid));
      }
    } catch (e) {
      dispatch(displayErrorNotification(errorMessage(e)));
    } finally {
      dispatch(syncWalletEnd());
    }
  };

export const deleteCard =
  ({
    userId,
    cardId,
    successMessage,
    errorMessage,
  }: {
    userId: string;
    cardId: string;
    successMessage: () => string;
    errorMessage: (e: Error) => string;
  }): AppThunk =>
  async (dispatch, getState) => {
    if (isDeletingCard(cardId)(getState())) {
      return;
    }

    try {
      dispatch(deleteCardStart(cardId));
      const accessToken = await getOrRefreshAccessToken(dispatch, getState);
      await userService.deleteCard(
        {
          userId,
          cardId,
        },
        accessToken,
      );
      dispatch(displaySuccessNotification(successMessage()));

      // refresh the user data
      const userid = selectUserResponse(getState())?.user.bpcAccountId;
      if (userid) {
        dispatch(getUser(userid));
      }
    } catch (e) {
      dispatch(displayErrorNotification(errorMessage(e)));
    } finally {
      dispatch(deleteCardEnd(cardId));
    }
  };

export const unregisterPqiUser =
  ({
    userId,
    successMessage,
    errorMessage,
  }: {
    userId: string;
    successMessage: () => string;
    errorMessage: (e: Error) => string;
  }): AppThunk =>
  async (dispatch, getState) => {
    if (isUnregisteringPqiUser(getState())) {
      return;
    }

    try {
      dispatch(unregisterPqiUserStart());
      const accessToken = await getOrRefreshAccessToken(dispatch, getState);
      await userService.unregisterPqiUser(userId, accessToken);
      dispatch(displaySuccessNotification(successMessage()));

      // refresh the user data
      const userid = selectUserResponse(getState())?.user.bpcAccountId;
      if (userid) {
        dispatch(getUser(userid));
      }
    } catch (e) {
      dispatch(displayErrorNotification(errorMessage(e)));
    } finally {
      dispatch(unregisterPqiUserEnd());
    }
  };
