import React from 'react';
import { Box, Flex, Text, useColorModeValue } from '@chakra-ui/react';
import {
  FiTrash,
  FiUser,
  FiBarChart2,
  FiFileText,
  FiMessageCircle,
  FiBell,
  FiSlash,
  FiTablet,
  FiList,
} from 'react-icons/fi';
import { FormattedMessage } from 'react-intl';
import SidebarItem from './SidebarItem';
import Labels from '../i18n/labels';
import RouteEnum from '../routes';
import { useAppSelector } from '../store/hooks';
import { selectSelectedRole } from '../store/authenticationSlice';
import Role from '../types/Role';
import EnvBadge from './EnvBadge';

function Sidebar() {
  const role = useAppSelector(selectSelectedRole);

  return (
    <Box
      bg={useColorModeValue('white', 'gray.900')}
      borderRight="1px"
      borderRightColor={useColorModeValue('gray.200', 'gray.700')}
      w="60"
      pos="fixed"
      h="full"
    >
      <Flex h="20" flexDirection="row" justifyContent="flex-start" alignItems="center" pl={1}>
        <img src="/logo.svg" height={60} width={60} alt="logo" />
        <Text fontSize="lg" fontWeight="bold" userSelect="none" color="black">
          <FormattedMessage id={Labels.NAME} />
          <EnvBadge />
        </Text>
      </Flex>
      <hr style={{ marginTop: '-1px', marginBottom: '10px' }} />
      {(role === Role.FraudOfficer || role === Role.FirstLineSupportAgent || role === Role.SecondLineSupportAgent) && (
        <SidebarItem to={RouteEnum.SEARCH_USER} icon={FiUser}>
          <FormattedMessage id={Labels.SIDEBAR_SEARCH_USER} />
        </SidebarItem>
      )}
      {role === Role.FraudOfficer && (
        <SidebarItem to={RouteEnum.REVOKE_USERS} icon={FiTrash}>
          <FormattedMessage id={Labels.SIDEBAR_BATCH_USER_DELETE} />
        </SidebarItem>
      )}
      {role === Role.OperationManager && (
        <SidebarItem to={RouteEnum.TERMS_AND_CONDITIONS} icon={FiFileText}>
          <FormattedMessage id={Labels.SIDEBAR_TERMS_AND_CONDITIONS} />
        </SidebarItem>
      )}
      {role === Role.SecondLineSupportAgent && (
        <SidebarItem to={RouteEnum.SEARCH_LOG} icon={FiBarChart2}>
          <FormattedMessage id={Labels.SIDEBAR_SEARCH_LOGS} />
        </SidebarItem>
      )}
      {(role === Role.FirstLineSupportAgent ||
        role === Role.SecondLineSupportAgent ||
        role === Role.OperationManager ||
        role === Role.FraudOfficer) && (
        <SidebarItem to={RouteEnum.SEARCH_OTP} icon={FiMessageCircle}>
          <FormattedMessage id={Labels.SIDEBAR_SEARCH_OTP} />
        </SidebarItem>
      )}
      {(role === Role.SecondLineSupportAgent || role === Role.OperationManager) && (
        <SidebarItem to={RouteEnum.STATUS} icon={FiBell}>
          <FormattedMessage id={Labels.SIDEBAR_STATUS} />
        </SidebarItem>
      )}
      {(role === Role.FirstLineSupportAgent || role === Role.SecondLineSupportAgent || role === Role.FraudOfficer) && (
        <SidebarItem to={RouteEnum.BLACKLIST} icon={FiSlash}>
          <FormattedMessage id={Labels.SIDEBAR_BLACKLIST} />
        </SidebarItem>
      )}
      {(role === Role.MosupUpload || role === Role.MosupPromote) && (
        <SidebarItem to={RouteEnum.SELECTOR_SDK} icon={FiTablet}>
          <FormattedMessage id={Labels.SIDEBAR_SELECTOR_SDK} />
        </SidebarItem>
      )}
      {(role === Role.MosupUpload || role === Role.MosupPromote) && (
        <SidebarItem to={RouteEnum.WHITELIST} icon={FiList}>
          <FormattedMessage id={Labels.SIDEBAR_WHITELIST} />
        </SidebarItem>
      )}
    </Box>
  );
}

export default Sidebar;
