import { AuthorizationRequest, StringMap } from '@openid/appauth';
import type { AccessToken, RefreshToken } from '../store/authenticationSlice';
import Role from '../types/Role';

const tabId = Math.random();

export const tryAcquireLock = async (callback: () => Promise<void>) =>
  navigator.locks.request(`completeAuthentication-${tabId}`, { ifAvailable: true, mode: 'exclusive' }, async (lock) => {
    if (lock) {
      return callback();
    }
    return undefined;
  });

export const getExtras = (authorizationRequest: AuthorizationRequest): StringMap => {
  const extras: StringMap = {};
  if (authorizationRequest.internal) {
    extras.code_verifier = authorizationRequest.internal.code_verifier;
  }
  return extras;
};

const decodeBase64Url = (base64Url: string) => {
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  return decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join(''),
  );
};

const decodeToken = <T>(token: string): T => {
  try {
    const base64Url = token.split('.')[1];
    const jsonPayload = decodeBase64Url(base64Url);
    return JSON.parse(jsonPayload) as T;
  } catch (e) {
    throw new Error(`Failed to decode token: ${e.message}`);
  }
};

export function decodeAccessToken(token: string) {
  const accessToken = decodeToken<AccessToken>(token);
  if (!accessToken.realm_access) {
    throw Error('Missing realm_access in your token');
  } else if (!accessToken.realm_access.roles || accessToken.realm_access.roles.length <= 0) {
    throw Error('You have no roles assigned.');
  }
  return accessToken;
}

export function isAccessTokenExpired(token: AccessToken | RefreshToken) {
  const now = Math.round(new Date().getTime() / 1000); // seconds
  return token.exp < now - 3;
}

export function extractAvailableRoles(accessToken: AccessToken) {
  return accessToken.realm_access.roles.filter((role) => Object.values(Role).includes(role));
}

export function defaultSelectedRole(accessToken: string) {
  const decodedAccessToken = decodeAccessToken(accessToken);
  const roles = extractAvailableRoles(decodedAccessToken);
  return roles[0];
}

export function buildLogoutUrl(authConfig: Record<string, string>) {
  return `${authConfig.issuerUrl}/protocol/openid-connect/logout?client_id=${
    authConfig.clientId
  }&post_logout_redirect_uri=${encodeURIComponent(authConfig.redirectUri)}`;
}
