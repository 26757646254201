import React from 'react';
import { Badge } from '@chakra-ui/react';
import Config from '../auth/authConfig';
import MasEnvironment from '../types/MasEnvironment';

const color = () => {
  switch (Config.environment) {
    case MasEnvironment.DEV:
      return 'green';
    case MasEnvironment.ACC:
      return 'yellow';
    case MasEnvironment.PREPROD:
      return 'orange';
    case MasEnvironment.BETA:
      return 'purple';
    case MasEnvironment.PROD:
      return 'red';
    default:
      return 'white';
  }
};

function EnvBadge() {
  return (
    <Badge ml="1" colorScheme={color()}>
      {Config.environment}
    </Badge>
  );
}

export default EnvBadge;
