enum Labels {
  NAME = 'name',
  /* Sidebar */
  SIDEBAR_SEARCH_USER = 'sidebar.search-user',
  SIDEBAR_BATCH_USER_DELETE = 'sidebar.batchUserDeletion',
  SIDEBAR_TERMS_AND_CONDITIONS = 'sidebar.termsAndConditions',
  SIDEBAR_SEARCH_LOGS = 'sidebar.search-log',
  SIDEBAR_SEARCH_OTP = 'sidebar.search-otp',
  SIDEBAR_STATUS = 'sidebar.status',
  SIDEBAR_BLACKLIST = 'sidebar.blacklist',
  SIDEBAR_SELECTOR_SDK = 'sidebar.selectorSdk',
  SIDEBAR_WHITELIST = 'sidebar.whitelist',
  /* Navbar */
  NAVBAR_LOGIN_BUTTON = 'navbar.login.button',
  NAVBAR_LOGOUT_BUTTON = 'navbar.logout.button',
  /* Auth */
  AUTH_ERROR_TITLE = 'auth.error.title',
  AUTH_ERROR_BODY = 'auth.error.body',
  AUTH_LOADING = 'auth.loading',
  AUTH_REQUIRED_TITLE = 'auth.required.title',
  AUTH_REQUIRED_BODY = 'auth.required.body',
  /* Roles */
  ROLE_OPERATION_MANAGER = 'role.operationManager',
  ROLE_FIRST_LINE_SUPPORT_AGENT = 'role.firstLineSupportAgent',
  ROLE_SECOND_LINE_SUPPORT_AGENT = 'role.secondLineSupportAgent',
  ROLE_FRAUD_OFFICER = 'role.fraudOfficer',
  ROLE_MOSUP_UPLOAD = 'role.MosupUpload',
  ROLE_MOSUP_PROMOTE = 'role.MosupPromote',
  /* Spinner */
  SPINNER_LABEL = 'spinner.label',
  /* BooleanText */
  TEXT_YES = 'text.yes',
  TEXT_NO = 'text.no',
  /* Textarea */
  TEXTAREA_COUNT = 'textarea.count',
  /* Search user */
  SEARCH_USER_TITLE = 'search.user.title',
  SEARCH_USER_SUBMIT_BUTTON = 'search.user.submit.button',
  SEARCH_USER_SUBMIT_TOOLTIP = 'search.user.submit.tooltip',
  SEARCH_USER_RESET_BUTTON = 'search.user.reset.button',
  SEARCH_USER_LABEL_BPCACCOUNTID = 'search.user.label.bpcAccountId',
  SEARCH_USER_LABEL_EMAIL = 'search.user.label.email',
  SEARCH_USER_LABEL_PHONE_NUMBER = 'search.user.label.phone.',
  SEARCH_USER_LABEL_FIRSTNAME = 'search.user.label.firstname ',
  SEARCH_USER_LABEL_LASTNAME = 'search.user.label.lastname',
  SEARCH_USER_HAS_MORE_RESULTS = 'search.user.label.hasMoreResults',
  SEARCH_USER_LOADING = 'search.user.loading',
  SEARCH_USER_EMPTY = 'search.user.empty',
  /* User */
  USER_TITLE = 'user.title',
  USER_BPC_ACCOUNT_ID = 'user.bpcAccountId',
  USER_SEAID = 'user.seaid',
  USER_PQI_ACCOUNT_ID = 'user.pqiAccountId',
  USER_CW_USER_ID = 'user.cwUserId',
  USER_ITSME_USER_ID = 'user.itsmeUserId',
  USER_EMAIL = 'user.email',
  USER_PHONE_NUMBER = 'user.phoneNumber',
  USER_FIRSTNAME = 'user.firstname',
  USER_LASTNAME = 'user.lastname',
  USER_ACCOUNT_STATUS = 'user.accountStatus',
  USER_ACCOUNT_STATUS_ACTIVE = 'user.accountstatus.active',
  USER_ACCOUNT_STATUS_DELETED = 'user.accountstatus.deleted',
  USER_LANGUAGE = 'user.language',
  USER_ACCOUNT_CREATION_DATE = 'user.accountCreationDate',
  USER_MARKETING_CONSENT = 'user.marketingConsentStatus',
  USER_TC_VERSION = 'user.tcVersion',
  USER_VAS_PROVIDER = 'user.vasProvider',
  USER_LOYALTY_CARDS = 'user.loyaltyCards',
  USER_VOUCHER_ISSUER = 'user.voucherIssuer',
  USER_REVOKE_ACCOUNT_BUTTON = 'user.revokeAccount.button',
  USER_DELETE_ACCOUNT_BUTTON = 'user.deleteAccount.button',
  USER_PQI_UNREGISTER_ACCOUNT_BUTTON = 'user.pqiUnregisterAccount.button',
  USER_LOADING = 'user.loading',
  USER_ERROR_MESSAGE = 'user.errorMessage',
  /* VAS */
  VAS_TITLE = 'vas.title',
  VAS_ID = 'vas.id',
  VAS_USER_ID = 'vas.userId',
  VAS_CONSENT = 'vas.consent',
  VAS_USER_CONSENT_EMPTY = 'vas.consent.empty',
  VAS_USER_CONSENT_GIVEN = 'vas.consent.given',
  VAS_USER_CONSENT_NOT_GIVEN = 'vas.consent.notGiven',
  VAS_USER_CONSENT_REFUSED = 'vas.consent.refused',
  /* Wallet */
  WALLET_STATUS = 'wallet.status',
  WALLET_STATUS_ACTIVE = 'wallet.status.active',
  WALLET_STATUS_DELETED = 'wallet.status.deleted',
  WALLET_STATUS_REVOKED = 'wallet.status.revoked',
  WALLET_STATUS_SUSPENDED = 'wallet.status.suspended',
  WALLET_CW_STATUS = 'wallet.cwStatus',
  WALLET_CW_STATUS_ACTIVE = 'wallet.cwStatus.active',
  WALLET_CW_STATUS_REVOKED = 'wallet.cwStatus.revoked',
  WALLET_CW_STATUS_SUSPENDED = 'wallet.cwStatus.suspended',
  WALLET_TITLE = 'user.wallet.cards.title',
  WALLET_CARD_ID = 'user.wallet.card.id',
  WALLET_CARD_ALIAS = 'user.wallet.card.alias',
  WALLET_CARD_MASKED_PAN = 'user.wallet.card.maskedPAN',
  WALLET_CARD_EXPIRY_DATE = 'user.wallet.card.expiryDate',
  WALLET_CARD_BANK_NAME = 'user.wallet.card.bankName',
  WALLET_CARD_STATUS = 'user.wallet.card.status',
  WALLET_CARD_STATUS_ACTIVE = 'user.wallet.card.status.active',
  WALLET_CARD_STATUS_WAITING = 'user.wallet.card.status.waiting',
  WALLET_CARD_EMPTY = 'user.wallet.emptyCards',
  /* Wallet sync */
  WALLET_SYNC_NOTIFICATION_SUCCESS = 'user.wallet.sync.success',
  WALLET_SYNC_NOTIFICATION_ERROR = 'user.wallet.sync.error',
  WALLET_SYNC_BUTTON = 'user.wallet.sync.button',
  WALLET_SYNC_CONFIRMATION_MODAL_TITLE = 'user.wallet.sync.modal.title',
  WALLET_SYNC_CONFIRMATION_MODAL_BODY = 'user.wallet.sync.modal.body',
  WALLET_SYNC_CONFIRMATION_MODAL_CONFIRM = 'user.wallet.sync.modal.confirm',
  WALLET_SYNC_CONFIRMATION_MODAL_CANCEL = 'user.wallet.sync.modal.cancel',
  /* Delete card */
  WALLET_DELETE_CARD_NOTIFICATION_SUCCESS = 'user.wallet.deleteCard.success',
  WALLET_DELETE_CARD_NOTIFICATION_ERROR = 'user.wallet.deleteCard.error',
  WALLET_DELETE_CARD_BUTTON = 'user.wallet.deleteCard.button',
  WALLET_DELETE_CARD_CONFIRMATION_MODAL_TITLE = 'user.wallet.deleteCard.modal.title',
  WALLET_DELETE_CARD_CONFIRMATION_MODAL_BODY = 'user.wallet.deleteCard.modal.body',
  WALLET_DELETE_CARD_CONFIRMATION_MODAL_CONFIRM = 'user.wallet.sync.deleteCard.confirm',
  WALLET_DELETE_CARD_CONFIRMATION_MODAL_CANCEL = 'user.wallet.sync.deleteCard.cancel',
  /* Last transaction */
  LAST_TRANSACTION_TITLE = 'lastTransaction.title',
  LAST_TRANSACTION_ID = 'lastTransaction.id',
  LAST_TRANSACTION_DATE = 'lastTransaction.date',
  LAST_TRANSACTION_TYPE = 'lastTransaction.type',
  LAST_TRANSACTION_STATUS = 'lastTransaction.status',
  LAST_TRANSACTION_STATUS_AUTH_OK = 'lastTransaction.status.authOk',
  LAST_TRANSACTION_STATUS_PRE_AUTH_OK = 'lastTransaction.status.preAuthOk',
  LAST_TRANSACTION_STATUS_AUTH_UNKNOWN = 'lastTransaction.status.unknownAuth',
  /* Last session */
  LAST_SESSION_TITLE = 'lastSession.title',
  LAST_SESSION_ID = 'lastSession.id',
  LAST_SESSION_DEVICE_ID = 'lastSession.deviceId',
  LAST_SESSION_CREATION_DATE = 'lastSession.creationDate',
  /* Delete user confirmation modal */
  USER_DELETE_CONFIRMATION_MODAL_TITLE = 'user.delete.confirmation.modal.title',
  USER_DELETE_CONFIRMATION_MODAL_BODY = 'user.delete.confirmation.modal.body',
  USER_DELETE_CONFIRMATION_MODAL_CONFIRM = 'user.delete.confirmation.modal.confirm',
  USER_DELETE_CONFIRMATION_MODAL_CANCEL = 'user.delete.confirmation.modal.cancel',
  USER_DELETE_NOTIFICATION_SUCCESS = 'user.delete.notification.success',
  USER_DELETE_NOTIFICATION_ERROR = 'user.delete.notification.error',
  /* Revoke user confirmation modal */
  USER_REVOKE_CONFIRMATION_MODAL_TITLE = 'user.revoke.confirmation.modal.title',
  USER_REVOKE_CONFIRMATION_MODAL_BODY = 'user.revoke.confirmation.modal.body',
  USER_REVOKE_CONFIRMATION_MODAL_CONFIRM = 'user.revoke.confirmation.modal.confirm',
  USER_REVOKE_CONFIRMATION_MODAL_CANCEL = 'user.revoke.confirmation.modal.cancel',
  USER_REVOKE_NOTIFICATION_SUCCESS = 'user.revoke.notification.success',
  USER_REVOKE_NOTIFICATION_ERROR = 'user.revoke.notification.error',
  /* PQI unregister user confirmation modal */
  USER_PQI_UNREGISTER_CONFIRMATION_MODAL_TITLE = 'user.pqi-unregister.confirmation.modal.title',
  USER_PQI_UNREGISTER_CONFIRMATION_MODAL_BODY = 'user.pqi-unregister.confirmation.modal.body',
  USER_PQI_UNREGISTER_CONFIRMATION_MODAL_CONFIRM = 'user.pqi-unregister.confirmation.modal.confirm',
  USER_PQI_UNREGISTER_CONFIRMATION_MODAL_CANCEL = 'user.pqi-unregister.confirmation.modal.cancel',
  USER_PQI_UNREGISTER_NOTIFICATION_SUCCESS = 'user.pqi-unregister.notification.success',
  USER_PQI_UNREGISTER_NOTIFICATION_ERROR = 'user.pqi-unregister.notification.error',
  /* Device */
  DEVICES_TITLE = 'devices.title',
  DEVICE_ID = 'device.deviceId',
  DEVICE_OS = 'device.os',
  DEVICE_OS_VERSION = 'device.osVersion',
  DEVICE_BRAND = 'device.brand',
  DEVICE_MODEL = 'device.model',
  DEVICE_STATUS = 'device.status',
  DEVICE_STATUS_ACTIVE = 'device.status.active',
  DEVICE_STATUS_DELETED = 'device.status.deleted',
  DEVICE_STATUS_REVOKED = 'device.status.revoked',
  DEVICE_CREATION_DATE = 'device.creationDate',
  DEVICE_APP_VERSION = 'device.appVersion',
  DEVICE_PLATFORM_VERIFIED = 'device.platformVerified',
  DEVICE_PLATFORM_VERIFICATION_FAILURES = 'device.platformVerificationFailures',
  DEVICE_HARDWARE_SECURED = 'device.hardwareSecured',
  DEVICE_DEVICE_KEY_CERTIFICATE = 'device.deviceKeyCertificate',
  DEVICE_PAYMENT_KEY_PIN_CERTIFICATE = 'device.paymentKeyPinCertificate',
  DEVICE_PAYMENT_KEY_BIOMETRICS_CERTIFICATE = 'device.paymentKeyBiometricsCertificate',
  DEVICE_EMPTY = 'device.empty',
  /* Device delete */
  DEVICE_DELETE_BUTTON = 'device.delete.button',
  DEVICE_DELETE_MODAL_TITLE = 'device.delete.modal.title',
  DEVICE_DELETE_MODAL_BODY = 'device.delete.modal.body',
  DEVICE_DELETE_MODAL_CONFIRM = 'device.delete.modal.confirm',
  DEVICE_DELETE_MODAL_CANCEL = 'device.card.delete.modal.cancel',
  DEVICE_DELETE_NOTIFICATION_SUCCESS = 'device.delete.notification.success',
  DEVICE_DELETE_NOTIFICATION_ERROR = 'device.delete.notification.error',
  /* Device revoke */
  DEVICE_REVOKE_BUTTON = 'device.revoke.button',
  DEVICE_REVOKE_MODAL_TITLE = 'device.revoke.modal.title',
  DEVICE_REVOKE_MODAL_BODY = 'device.revoke.modal.body',
  DEVICE_REVOKE_MODAL_CONFIRM = 'device.revoke.modal.confirm',
  DEVICE_REVOKE_MODAL_CANCEL = 'device.card.revoke.modal.cancel',
  DEVICE_REVOKE_NOTIFICATION_SUCCESS = 'device.revoke.notification.success',
  DEVICE_REVOKE_NOTIFICATION_ERROR = 'device.revoke.notification.error',
  /* Deletion in batch */
  BATCH_USER_REVOCATION_TITLE = 'batch.user.revocation.title',
  BATCH_USER_REVOCATION_JOB_TITLE = 'batch.user.revocation.job.title',
  BATCH_USER_REVOCATION_JOB_SUBTITLE = 'batch.user.revocation.job.subtitle',
  BATCH_USER_REVOCATION_EMPTY_TITLE = 'batch.user.revocation.empty.title',
  BATCH_USER_REVOCATION_EMPTY_SUBTITLE = 'batch.user.revocation.empty.subtitle',
  BATCH_USER_REVOCATION_ERROR_TITLE = 'batch.user.revocation.error.title',
  BATCH_USER_REVOCATION_TABLE_BPC_ACCOUNT_ID = 'batch.user.revocation.table.bpcAccountId',
  BATCH_USER_REVOCATION_TABLE_SEA_ID = 'batch.user.revocation.table.seaId',
  BATCH_USER_REVOCATION_TABLE_STATUS = 'batch.user.revocation.table.status',
  BATCH_USER_REVOCATION_TABLE_DELETION_DATE = 'batch.user.revocation.table.deletionDate',
  BATCH_USER_REVOCATION_TABLE_ERROR = 'batch.user.revocation.table.error',
  BATCH_USER_REVOCATION_TABLE_EXPORT = 'batch.user.revocation.table.export',
  BATCH_USER_REVOCATION_MODAL_TITLE = 'batch.user.revocation.modal.title',
  BATCH_USER_REVOCATION_MODAL_CANCEL = 'batch.user.revocation.modal.cancel',
  BATCH_USER_REVOCATION_MODAL_UPLOAD = 'batch.user.revocation.modal.upload',
  BATCH_USER_REVOCATION_MODAL_NEW = 'batch.user.revocation.modal.new',
  BATCH_USER_REVOCATION_UPLOAD_SUCCESS = 'batch.user.revocation.upload.success',
  BATCH_USER_REVOCATION_UPLOAD_ERROR = 'batch.user.revocation.upload.error',
  BATCH_USER_REVOCATION_UPLOAD_INPUT_TITLE = 'batch.user.revocation.upload.input.title',
  BATCH_USER_REVOCATION_UPLOAD_INPUT_MESSAGE = 'batch.user.revocation.upload.input.message',
  /* Search logs */
  SEARCH_LOG_TITLE = 'search.log.title',
  SEARCH_LOG_SUBMIT_BUTTON = 'search.log.submit.button',
  SEARCH_LOG_RESET_BUTTON = 'search.log.reset.button',
  SEARCH_LOG_LABEL_LEVEL = 'search.log.label.level',
  SEARCH_LOG_LABEL_MIN_LEVEL = 'search.log.label.minLevel',
  SEARCH_LOG_LABEL_STARTDATE = 'search.log.label.startDate',
  SEARCH_LOG_LABEL_ENDDATE = 'search.log.label.endDate',
  SEARCH_LOG_LABEL_BPCACCOUNTID = 'search.log.label.bpcAccountId',
  SEARCH_LOG_LABEL_MESSAGE = 'search.log.label.message',
  SEARCH_LOG_LABEL_CONTAINER = 'search.log.label.container',
  SEARCH_LOG_LABEL_CONTAINER_MAC = 'search.log.label.container.mac',
  SEARCH_LOG_LABEL_CONTAINER_KEYCLOAK = 'search.log.label.container.keycloak',
  SEARCH_LOG_LABEL_CONTAINER_ALL = 'search.log.label.container.all',
  SEARCH_LOG_LABEL_LEVEL_TRACE = 'search.log.label.level.trace',
  SEARCH_LOG_LABEL_LEVEL_DEBUG = 'search.log.label.level.debug',
  SEARCH_LOG_LABEL_LEVEL_INFO = 'search.log.label.level.info',
  SEARCH_LOG_LABEL_LEVEL_WARN = 'search.log.label.level.warn',
  SEARCH_LOG_LABEL_LEVEL_ERROR = 'search.log.label.level.error',
  SEARCH_LOG_LABEL_LINE_NUMBER = 'search.log.label.lineNumber',
  SEARCH_LOG_LABEL_TIMESTAMP = 'search.log.label.timestamp',
  SEARCH_LOG_LABEL_DEVICEID = 'search.log.label.deviceId',
  SEARCH_LOG_HAS_MORE_RESULTS = 'search.log.label.hasMoreResults',
  SEARCH_LOG_LOADING = 'search.log.loading',
  SEARCH_LOG_EMPTY = 'search.log.empty',
  /* Search OTP */
  SEARCH_OTP_TITLE = 'search.otp.title',
  SEARCH_OTP_LABEL_PREFIX = 'search.otp.label.prefix',
  SEARCH_OTP_LABEL_SUFFIX = 'search.otp.label.suffix',
  SEARCH_OTP_LABEL_PHONE_PREFIX = 'search.otp.label.phonePrefix',
  SEARCH_OTP_LABEL_EMAIL_PREFIX = 'search.otp.label.emailPrefix',
  SEARCH_OTP_LABEL_IP_PREFIX = 'search.otp.label.ipPrefix',
  SEARCH_OTP_LABEL_URL = 'search.otp.label.url',
  SEARCH_OTP_LABEL_REMAINING_CODES = 'search.otp.label.remainingCodes',
  SEARCH_OTP_SUBMIT_BUTTON = 'search.otp.submit.button',
  SEARCH_OTP_RESET_BUTTON = 'search.otp.reset.button',
  /* Mas status */
  MAS_STATUS_TITLE = 'masStatus.title',
  MAS_STATUS_ERROR_MESSAGE = 'masStatus.errorMessage',
  MAS_STATUS_TAB_MAS_CONFIGURATION = 'masStatus.tab.masConfiguration',
  MAS_STATUS_TAB_IDP_CONFIGURATION = 'masStatus.tab.idpConfiguration',
  MAS_STATUS_TAB_HTTP_CLIENT_STATUS = 'masStatus.tab.httpClientStatus',
  MAS_STATUS_CONFIG_NAME = 'masStatus.config.name',
  MAS_STATUS_CONFIG_KEY = 'masStatus.config.key',
  MAS_STATUS_CONFIG_VALUE = 'masStatus.config.value',
  MAS_STATUS_HTTP_CLIENT_NAME = 'masStatus.httpClient.name',
  MAS_STATUS_HTTP_CLIENT_URL = 'masStatus.httpClient.url',
  MAS_STATUS_HTTP_CLIENT_STATUS = 'masStatus.httpClient.status',
  MAS_STATUS_HTTP_CLIENT_SSL_STATUS = 'masStatus.httpClient.sslStatus',
  MAS_STATUS_REFRESH_BUTTON = 'masStatus.refreshButton',
  /* Blacklist */
  BLACKLIST_TITLE = 'blacklist.title',
  BLACKLIST_RESULT_HEADER = 'blacklist.result.header',
  BLACKLIST_ENTRY_TYPE = 'blacklist.input.type.label',
  BLACKLIST_ENTRY_VALUE = 'blacklist.input.value.label',
  BLACKLIST_SUBMIT_BUTTON = 'blacklist.submit.button',
  BLACKLIST_OPTION_PHONE = 'blacklist.option.phone',
  BLACKLIST_OPTION_EMAIL = 'blacklist.option.email',
  BLACKLIST_OPTION_DEVICE_ID = 'blacklist.option.deviceId',
  BLACKLIST_LABEL_TYPE = 'blacklist.label.type',
  BLACKLIST_LABEL_VALUE = 'blacklist.label.value',
  BLACKLIST_LABEL_CREATION_DATE = 'blacklist.label.creationDate',
  /* Device delete */
  BLACKLIST_DELETE_BUTTON = 'blacklist.delete.button',
  BLACKLIST_DELETE_MODAL_TITLE = 'blacklist.delete.modal.title',
  BLACKLIST_DELETE_MODAL_BODY = 'blacklist.delete.modal.body',
  BLACKLIST_DELETE_MODAL_CONFIRM = 'blacklist.delete.modal.confirm',
  BLACKLIST_DELETE_MODAL_CANCEL = 'blacklist.card.delete.modal.cancel',
  BLACKLIST_DELETE_NOTIFICATION_SUCCESS = 'blacklist.delete.notification.success',
  BLACKLIST_DELETE_NOTIFICATION_ERROR = 'blacklist.delete.notification.error',
  BLACKLIST_EMPTY = 'blacklist.empty',
  /* MoSup */
  MOSUP_CURRENT_VERSION_HEADER = 'mosup.current.header',
  MOSUP_STAGING_VERSION_HEADER = 'mosup.staging.header',
  MOSUP_VERSION_EMPTY = 'mosup.current.empty',
  MOSUP_VERSION_PLATFORM = 'mosup.current.platform',
  MOSUP_VERSION_UPLOADED_AT = 'mosup.current.uploadedAt',
  MOSUP_VERSION_UPLOADED_BY = 'mosup.current.uploadedBy',
  MOSUP_VERSION_PROMOTED_AT = 'mosup.current.promotedAt',
  MOSUP_VERSION_PROMOTED_BY = 'mosup.current.promotedBy',
  MOSUP_VERSION_COMMENT = 'mosup.current.comment',
  MOSUP_DOWNLOAD_TOOLTIP = 'mosup.download.tooltip',
  MOSUP_VIEW_TOOLTIP = 'mosup.view.tooltip',
  MOSUP_VIEW_MODAL_TITLE = 'mosup.view.modalTitle',
  MOSUP_VIEW_TAB_RAW = 'mosup.view.tab.raw',
  MOSUP_VIEW_TAB_DECODED = 'mosup.view.tab.decoded',
  MOSUP_PROMOTE_BUTTON = 'mosup.promote.button',
  MOSUP_PROMOTE_MODAL_TITLE = 'mosup.promote.modalTitle',
  MOSUP_PROMOTE_MODAL_BODY = 'mosup.promote.modalBody',
  MOSUP_PROMOTE_MODAL_CONFIRM_BUTTON = 'mosup.promote.modalConfirmButton',
  MOSUP_PROMOTE_MODAL_CANCEL_BUTTON = 'mosup.promote.modalCancelButton',
  MOSUP_PROMOTE_NOTIFICATION_SUCCESS = 'mosup.promote.notificationSuccess',
  MOSUP_PROMOTE_NOTIFICATION_ERROR = 'mosup.promote.notificationError',
  /* Selector SDK */
  SELECTOR_SDK_TITLE = 'selectorSdk.title',
  SELECTOR_SDK_ANDROID = 'selectorSdk.android',
  SELECTOR_SDK_IOS = 'selectorSdk.ios',
  SELECTOR_SDK_UPLOAD_BUTTON = 'selectorSdk.upload.button',
  SELECTOR_SDK_UPLOAD_MODAL_TITLE = 'selectorSdk.upload.modal.title',
  SELECTOR_SDK_UPLOAD_MODAL_CONFIRM = 'selectorSdk.upload.modal.confirm',
  SELECTOR_SDK_UPLOAD_MODAL_CANCEL = 'selectorSdk.upload.modal.confirmButton',
  SELECTOR_SDK_UPLOAD_LABEL_PLATFORM = 'selectorSdk.upload.modal.labelPlatform',
  SELECTOR_SDK_UPLOAD_LABEL_COMMENT = 'selectorSdk.upload.modal.labelComment',
  SELECTOR_SDK_UPLOAD_INPUT_TITLE = 'selectorSdk.upload.modal.inputTitle',
  SELECTOR_SDK_UPLOAD_INPUT_MESSAGE = 'selectorSdk.upload.modal.inputMessage',
  SELECTOR_SDK_UPLOAD_SUCCESS = 'selectorSdk.upload.success',
  SELECTOR_SDK_UPLOAD_ERROR = 'selectorSdk.upload.error',
  /* Whitelist */
  WHITELIST = 'whitelist',
  WHITELIST_TITLE = 'whitelist.title',
  WHITELIST_UPLOAD_BUTTON = 'whitelist.upload.button',
  WHITELIST_UPLOAD_MODAL_TITLE = 'whitelist.upload.modal.title',
  WHITELIST_UPLOAD_MODAL_CONFIRM = 'whitelist.upload.modal.confirm',
  WHITELIST_UPLOAD_MODAL_CANCEL = 'whitelist.upload.modal.confirmButton',
  WHITELIST_UPLOAD_LABEL_COMMENT = 'whitelist.upload.modal.labelComment',
  WHITELIST_UPLOAD_INPUT_TITLE = 'whitelist.upload.modal.inputTitle',
  WHITELIST_UPLOAD_INPUT_MESSAGE = 'whitelist.upload.modal.inputMessage',
  WHITELIST_UPLOAD_SUCCESS = 'whitelist.upload.success',
  WHITELIST_UPLOAD_ERROR = 'whitelist.upload.error',
  /* Not found */
  NOT_FOUND_TITLE = 'notFound.title',
  NOT_FOUND_SUBTITLE = 'notFound.subtitle',
  NOT_FOUND_BUTTON = 'notFound.button',
}

export default Labels;
