import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk, RootState } from './types';
import * as masStatusService from '../service/masStatusService';
import { getOrRefreshAccessToken } from './authenticationSlice';
import { ReadMasStatusResponse } from '../types/ReadMasStatusResponse';

export enum MasStatus {
  Idle = 'Idle',
  Loading = 'Loading',
  Success = 'Success',
  Error = 'Error',
}

export interface MasStatusSlice {
  status: MasStatus;
  readMasStatusResponse: ReadMasStatusResponse | undefined;
  error: string | undefined;
}

export const initialState: MasStatusSlice = {
  status: MasStatus.Idle,
  readMasStatusResponse: undefined,
  error: undefined,
};

/* Reducer */
export const masStatusSlice = createSlice({
  name: 'masStatus',
  initialState,
  reducers: {
    // Get
    readMasStatusStart: (state: MasStatusSlice) => {
      state.status = MasStatus.Loading;
      state.readMasStatusResponse = undefined;
      state.error = undefined;
    },
    readMasStatusSuccess: (state: MasStatusSlice, action: PayloadAction<ReadMasStatusResponse>) => {
      state.status = MasStatus.Success;
      state.readMasStatusResponse = action.payload;
      state.error = undefined;
    },
    readMasStatusError: (state: MasStatusSlice, action: PayloadAction<string>) => {
      state.status = MasStatus.Error;
      state.readMasStatusResponse = undefined;
      state.error = action.payload;
    },
  },
});

export const { readMasStatusStart, readMasStatusSuccess, readMasStatusError } = masStatusSlice.actions;

/* Selectors */
export const selectMasStatus = (state: RootState) => state.masStatus.status;
export const selectReadMasStatusResponse = (state: RootState) => state.masStatus.readMasStatusResponse;
export const selectMasStatusError = (state: RootState) => state.masStatus.error;

/* Thunk */
export const readMasStatus = (): AppThunk => async (dispatch, getState) => {
  if (selectMasStatus(getState()) === MasStatus.Loading) {
    return;
  }

  try {
    dispatch(readMasStatusStart());
    const accessToken = await getOrRefreshAccessToken(dispatch, getState);
    const getUserResponse = await masStatusService.default(accessToken);
    dispatch(readMasStatusSuccess(getUserResponse));
  } catch (e) {
    dispatch(readMasStatusError(e.toString()));
  }
};
