import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from './types';
import { getOrRefreshAccessToken } from './authenticationSlice';
import { adminCountUsers } from '../service/userService';
import AdminUserCountResponse from '../types/AdminUserCountResponse';

export enum CountUsersStatus {
  Loading = 'Loading',
  Success = 'Success',
  Error = 'Error',
}

export interface UsersCountState {
  status: CountUsersStatus;
  count: number;
}

export const initialState: UsersCountState = {
  status: CountUsersStatus.Loading,
  count: 0,
};

export const countUsersSlice = createSlice({
  name: 'countUsers',
  initialState,
  reducers: {
    // get
    countUsersStart: (state: UsersCountState) => {
      state.status = CountUsersStatus.Loading;
      state.count = 0;
    },
    countUsersSuccess: (state: UsersCountState, action: PayloadAction<AdminUserCountResponse>) => {
      state.status = CountUsersStatus.Success;
      state.count = action.payload.count;
    },
    countUsersError: (state: UsersCountState) => {
      state.status = CountUsersStatus.Error;
      state.count = 0;
    },
  },
});

export const { countUsersStart, countUsersSuccess, countUsersError } = countUsersSlice.actions;

export const countUserThunk = (): AppThunk => async (dispatch, getState) => {
  try {
    dispatch(countUsersStart());
    const accessToken = await getOrRefreshAccessToken(dispatch, getState);
    const response = await adminCountUsers(accessToken);
    dispatch(countUsersSuccess(response));
  } catch (e) {
    dispatch(countUsersError());
  }
};
