import { Alert, AlertDescription, AlertIcon, AlertTitle } from '@chakra-ui/react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import Labels from '../../i18n/labels';

type AuthGuardErrorProps = {
  error: string;
};

export default function AuthGuardError({ error }: AuthGuardErrorProps) {
  return (
    <Alert
      status="error"
      variant="subtle"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      textAlign="center"
      padding="30px"
      data-testid="authenticationError"
    >
      <AlertIcon boxSize="40px" mr={0} />
      <AlertTitle mt={4} mb={1} fontSize="lg">
        <FormattedMessage id={Labels.AUTH_ERROR_TITLE} />
      </AlertTitle>
      <AlertDescription maxWidth="sm">{error}</AlertDescription>
    </Alert>
  );
}
