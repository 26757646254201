import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { SearchUserResult } from 'types/SearchUserResult';
import type { SearchUserRequest } from 'types/SearchUserRequest';
import type { AppThunk, RootState } from './types';
import { searchUsers } from '../service/userService';
import { getOrRefreshAccessToken } from './authenticationSlice';
import { SearchUserResponse } from '../types/SearchUserResponse';
import { displayErrorNotification } from './notificationSlice';

export enum SearchUserStatus {
  Idle = 'Idle',
  Loading = 'Loading',
  Success = 'Success',
  Error = 'Error',
}

export interface SearchUsersState {
  status: SearchUserStatus;
  users: SearchUserResult[];
  moreResults: boolean;
}

export const initialState: SearchUsersState = {
  status: SearchUserStatus.Idle,
  users: [],
  moreResults: false,
};

/* Reducer */
export const searchUserSlice = createSlice({
  name: 'searchUser',
  initialState,
  reducers: {
    searchUserStart: (state: SearchUsersState) => {
      state.status = SearchUserStatus.Loading;
    },
    searchUserSuccess: (state: SearchUsersState, action: PayloadAction<SearchUserResponse>) => {
      state.status = SearchUserStatus.Success;
      state.users = action.payload.results;
      state.moreResults = action.payload.hasMoreResults;
    },
    searchUserError: (state: SearchUsersState) => {
      state.status = SearchUserStatus.Error;
      state.users = [];
      state.moreResults = false;
    },
  },
});

export const { searchUserStart, searchUserSuccess, searchUserError } = searchUserSlice.actions;

/* Thunk */
export const searchUser =
  (userSearchRequest: SearchUserRequest): AppThunk =>
  async (dispatch, getState) => {
    try {
      dispatch(searchUserStart());
      const accessToken = await getOrRefreshAccessToken(dispatch, getState);
      const response = await searchUsers(userSearchRequest, accessToken);
      dispatch(searchUserSuccess(response));
    } catch (e) {
      dispatch(displayErrorNotification(e.toString()));
      dispatch(searchUserError());
    }
  };

/* Selectors */
export const selectUserSearchUsers = (state: RootState) => state.searchUser.users;
export const selectSearchUserHasMore = (state: RootState) => state.searchUser.moreResults;
export const selectSearchUserStatus = (state: RootState) => state.searchUser.status;
