import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk, RootState } from './types';
import { getOrRefreshAccessToken } from './authenticationSlice';
import { displayErrorNotification, displaySuccessNotification } from './notificationSlice';
import {
  MosupAssetType,
  MosupCurrentAssetVersion,
  MosupReadResponse,
  MosupStagingAssetVersion,
  MosupUploadRequest,
  SelectorSdkPlatform,
} from '../types/Mosup';
import * as MosupService from '../service/mosupService';

export enum SelectorSdkStatus {
  Idle = 'Idle',
  Loading = 'Loading',
  Success = 'Success',
}

export interface SelectorSdkState {
  status: SelectorSdkStatus;
  isUploading: {
    [MosupAssetType.SelectorSdkAndroid]: boolean;
    [MosupAssetType.SelectorSdkIos]: boolean;
  };
  isPromoting: {
    [MosupAssetType.SelectorSdkAndroid]: boolean;
    [MosupAssetType.SelectorSdkIos]: boolean;
  };
  currentVersions: {
    [MosupAssetType.SelectorSdkAndroid]: MosupCurrentAssetVersion | undefined;
    [MosupAssetType.SelectorSdkIos]: MosupCurrentAssetVersion | undefined;
  };
  stagingVersions: {
    [MosupAssetType.SelectorSdkAndroid]: MosupStagingAssetVersion | undefined;
    [MosupAssetType.SelectorSdkIos]: MosupStagingAssetVersion | undefined;
  };
}

export const initialState: SelectorSdkState = {
  status: SelectorSdkStatus.Idle,
  isUploading: {
    [MosupAssetType.SelectorSdkAndroid]: false,
    [MosupAssetType.SelectorSdkIos]: false,
  },
  isPromoting: {
    [MosupAssetType.SelectorSdkAndroid]: false,
    [MosupAssetType.SelectorSdkIos]: false,
  },
  currentVersions: {
    [MosupAssetType.SelectorSdkAndroid]: undefined,
    [MosupAssetType.SelectorSdkIos]: undefined,
  },
  stagingVersions: {
    [MosupAssetType.SelectorSdkAndroid]: undefined,
    [MosupAssetType.SelectorSdkIos]: undefined,
  },
};

/* Reducer */
export const selectorSdkSlice = createSlice({
  name: 'selectorSdk',
  initialState,
  reducers: {
    // Read Selector SDK versions
    readSelectorSdkVersionsStart: (state: SelectorSdkState) => {
      state.status = SelectorSdkStatus.Loading;
    },
    readSelectorSdkVersionsSuccess: (
      state: SelectorSdkState,
      action: PayloadAction<{
        [MosupAssetType.SelectorSdkAndroid]: MosupReadResponse;
        [MosupAssetType.SelectorSdkIos]: MosupReadResponse;
      }>,
    ) => {
      state.status = SelectorSdkStatus.Success;
      state.stagingVersions = {
        [MosupAssetType.SelectorSdkAndroid]: action.payload[MosupAssetType.SelectorSdkAndroid].stagingVersion,
        [MosupAssetType.SelectorSdkIos]: action.payload[MosupAssetType.SelectorSdkIos].stagingVersion,
      };
      state.currentVersions = {
        [MosupAssetType.SelectorSdkAndroid]: action.payload[MosupAssetType.SelectorSdkAndroid].currentVersion,
        [MosupAssetType.SelectorSdkIos]: action.payload[MosupAssetType.SelectorSdkIos].currentVersion,
      };
    },
    readSelectorSdkVersionsError: (state: SelectorSdkState) => {
      state.status = SelectorSdkStatus.Idle;
      state.currentVersions = {
        [MosupAssetType.SelectorSdkAndroid]: undefined,
        [MosupAssetType.SelectorSdkIos]: undefined,
      };
      state.stagingVersions = {
        [MosupAssetType.SelectorSdkAndroid]: undefined,
        [MosupAssetType.SelectorSdkIos]: undefined,
      };
    },
    // Upload new Selector SDK version
    uploadSelectorSdkStart: (state: SelectorSdkState, action: PayloadAction<SelectorSdkPlatform>) => {
      state.isUploading[action.payload] = true;
    },
    uploadSelectorSdkSuccess: (state: SelectorSdkState, action: PayloadAction<SelectorSdkPlatform>) => {
      state.isUploading[action.payload] = false;
    },
    uploadSelectorSdkError: (state: SelectorSdkState, action: PayloadAction<SelectorSdkPlatform>) => {
      state.isUploading[action.payload] = false;
    },
    // Promote Selector SDK version
    promoteSelectorSdkStart: (state: SelectorSdkState, action: PayloadAction<SelectorSdkPlatform>) => {
      state.isPromoting[action.payload] = true;
    },
    promoteSelectorSdkSuccess: (state: SelectorSdkState, action: PayloadAction<SelectorSdkPlatform>) => {
      state.isPromoting[action.payload] = false;
    },
    promoteSelectorSdkError: (state: SelectorSdkState, action: PayloadAction<SelectorSdkPlatform>) => {
      state.isPromoting[action.payload] = false;
    },
  },
});

export const {
  readSelectorSdkVersionsStart,
  readSelectorSdkVersionsSuccess,
  readSelectorSdkVersionsError,
  uploadSelectorSdkStart,
  uploadSelectorSdkSuccess,
  uploadSelectorSdkError,
  promoteSelectorSdkStart,
  promoteSelectorSdkSuccess,
  promoteSelectorSdkError,
} = selectorSdkSlice.actions;

/* Selectors */
export const selectSelectorSdkStatus = (state: RootState) => state.selectorSdk.status;
export const selectSelectorSdkCurrentVersions = createSelector(
  (state: RootState) => state.selectorSdk.currentVersions,
  (currentVersions) =>
    [currentVersions[MosupAssetType.SelectorSdkAndroid], currentVersions[MosupAssetType.SelectorSdkIos]].filter(
      (version) => version !== undefined,
    ) as MosupCurrentAssetVersion[],
);
export const selectSelectorSdkStagingVersions = createSelector(
  (state: RootState) => state.selectorSdk.stagingVersions,
  (stagingVersions) =>
    [stagingVersions[MosupAssetType.SelectorSdkAndroid], stagingVersions[MosupAssetType.SelectorSdkIos]].filter(
      (version) => version !== undefined,
    ) as MosupStagingAssetVersion[],
);
export const selectSelectorSdkPromoting = (platform: SelectorSdkPlatform) => (state: RootState) =>
  state.selectorSdk.isPromoting[platform];
export const selectSelectorSdkUploading = (platform: SelectorSdkPlatform) => (state: RootState) =>
  state.selectorSdk.isUploading[platform];

/* Thunk */
export const readSelectorSdkVersions = (): AppThunk => async (dispatch, getState) => {
  try {
    if (selectSelectorSdkStatus(getState()) === SelectorSdkStatus.Loading) {
      return;
    }
    dispatch(readSelectorSdkVersionsStart());
    const accessToken = await getOrRefreshAccessToken(dispatch, getState);
    const response = await Promise.all([
      MosupService.read(MosupAssetType.SelectorSdkIos, accessToken),
      MosupService.read(MosupAssetType.SelectorSdkAndroid, accessToken),
    ]);
    dispatch(
      readSelectorSdkVersionsSuccess({
        [MosupAssetType.SelectorSdkIos]: response[0],
        [MosupAssetType.SelectorSdkAndroid]: response[1],
      }),
    );
  } catch (e) {
    dispatch(displayErrorNotification(e.toString()));
    dispatch(readSelectorSdkVersionsError());
  }
};

export const uploadedSelectorSdkVersion =
  ({
    platform,
    uploadRequest,
    successMessage,
    errorMessage,
  }: {
    platform: SelectorSdkPlatform;
    uploadRequest: MosupUploadRequest;
    successMessage: () => string;
    errorMessage: (e: Error) => string;
  }): AppThunk =>
  async (dispatch, getState) => {
    try {
      dispatch(uploadSelectorSdkStart(platform));
      const accessToken = await getOrRefreshAccessToken(dispatch, getState);
      await MosupService.upload(platform, uploadRequest, accessToken);
      dispatch(uploadSelectorSdkSuccess(platform));
      dispatch(displaySuccessNotification(successMessage()));
      dispatch(readSelectorSdkVersions()); // refresh
    } catch (e) {
      dispatch(displayErrorNotification(errorMessage(e)));
      dispatch(uploadSelectorSdkError(platform));
    }
  };

export const promoteSelectorSdkVersion =
  ({
    platform,
    successMessage,
    errorMessage,
  }: {
    platform: SelectorSdkPlatform;
    successMessage: () => string;
    errorMessage: (e: Error) => string;
  }): AppThunk =>
  async (dispatch, getState) => {
    try {
      dispatch(promoteSelectorSdkStart(platform));
      const accessToken = await getOrRefreshAccessToken(dispatch, getState);
      await MosupService.promote(platform, accessToken);
      dispatch(promoteSelectorSdkSuccess(platform));
      dispatch(displaySuccessNotification(successMessage()));
      dispatch(readSelectorSdkVersions()); // refresh
    } catch (e) {
      dispatch(displayErrorNotification(errorMessage(e)));
      dispatch(promoteSelectorSdkError(platform));
    }
  };
