enum AppRoute {
  HOME = '/',
  SEARCH_USER = '/users',
  USER = '/users/:id',
  REVOKE_USERS = '/batch-user-revocation',
  TERMS_AND_CONDITIONS = '/terms-and-conditions',
  SEARCH_LOG = '/logs',
  SEARCH_OTP = '/otp',
  STATUS = '/status',
  BLACKLIST = '/blacklist',
  SELECTOR_SDK = '/mosup/selector-sdk',
  WHITELIST = '/mosup/whitelist',
}

export default AppRoute;
