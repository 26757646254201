import { AnyAction, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ThunkDispatch } from 'redux-thunk/src/types';
import Logger from 'logger';
import type { RootState, AppThunk } from './types';
import * as authService from '../auth/authService';
import {
  decodeAccessToken,
  defaultSelectedRole,
  extractAvailableRoles,
  isAccessTokenExpired,
  tryAcquireLock,
} from '../auth/authUtils';
import * as authStorage from '../auth/authStorage';
import Role, { byEnumOrder } from '../types/Role';

export enum AuthenticationStatus {
  Unauthenticating = 'Unauthenticating',
  Unauthenticated = 'Unauthenticated',
  Authenticating = 'Authenticating',
  Authenticated = 'Authenticated',
  AuthenticationError = 'AuthenticationError',
}

export type AccessToken = {
  exp: number;
  sid: string;
  realm_access: {
    roles: Role[];
  };
};

export type RefreshToken = {
  exp: number;
};

export type LoginSuccessPayload = {
  accessToken: string;
  idToken: string | undefined;
};

export interface AuthenticationState {
  status: AuthenticationStatus;
  error: string | undefined;
  accessToken: string | undefined;
  idToken: string | undefined;
  role: Role | undefined;
}

const initialState: AuthenticationState = {
  status: AuthenticationStatus.Authenticating,
  error: undefined,
  accessToken: undefined,
  idToken: undefined,
  role: undefined,
};

/* Reducer */
export const authenticationSlice = createSlice({
  name: 'authentication',
  initialState,
  reducers: {
    selectRole: (state: AuthenticationState, action: PayloadAction<Role>) => {
      state.role = action.payload;
    },
    loginStart: (state: AuthenticationState) => {
      state.status = AuthenticationStatus.Authenticating;
    },
    loginStop: (state: AuthenticationState) => {
      state.status = AuthenticationStatus.Unauthenticated;
    },
    loginSuccess: (state: AuthenticationState, action: PayloadAction<LoginSuccessPayload>) => {
      state.accessToken = action.payload.accessToken;
      state.idToken = action.payload.idToken;
      state.status = AuthenticationStatus.Authenticated;
      state.error = undefined;
      state.role = defaultSelectedRole(action.payload.accessToken);
    },
    loginError: (state: AuthenticationState, action: PayloadAction<string>) => {
      state.status = AuthenticationStatus.AuthenticationError;
      state.error = action.payload;
    },
    logoutStart: (state: AuthenticationState) => {
      state.status = AuthenticationStatus.Unauthenticating;
    },
    logoutSuccess: (state: AuthenticationState) => {
      state.accessToken = undefined;
      state.idToken = undefined;
      state.status = AuthenticationStatus.Unauthenticated;
    },
    refreshTokens: (state: AuthenticationState, action: PayloadAction<LoginSuccessPayload>) => {
      state.accessToken = action.payload.accessToken;
      state.idToken = action.payload.idToken;
      // The list of roles is not expected to change when refreshing the token.
    },
  },
});

export const {
  selectRole,
  loginStart,
  loginStop,
  loginSuccess,
  loginError,
  logoutStart,
  logoutSuccess,
  refreshTokens,
} = authenticationSlice.actions;

/* Selectors */
export const selectAccessToken = (state: RootState) => {
  if (!state.authentication.accessToken) {
    throw new Error('Access token is missing');
  }
  return state.authentication.accessToken as string;
};
export const selectAccessTokenDecoded = createSelector(selectAccessToken, (accessToken) =>
  decodeAccessToken(accessToken),
);
export const selectSelectedRole = (state: RootState) => state.authentication.role;
export const selectAvailableRoles = createSelector(selectAccessTokenDecoded, (accessTokenDecoded) =>
  [...extractAvailableRoles(accessTokenDecoded)].sort(byEnumOrder),
);
export const selectStatus = (state: RootState) => state.authentication.status;
export const selectError = (state: RootState) => state.authentication.error;

/* Thunk */
export const login = (): AppThunk => async (dispatch) => {
  try {
    dispatch(loginStart());
    await authService.startAuthentication();
  } catch (e) {
    dispatch(loginError(e.message));
  }
};

export const logout = (): AppThunk => async (dispatch, getState) => {
  dispatch(logoutStart());
  await authService.logout();
  const { sid } = selectAccessTokenDecoded(getState());
  authStorage.removeRefreshToken(sid);
  dispatch(logoutSuccess());
};

export const completeAuthentication = (): AppThunk => async (dispatch) =>
  tryAcquireLock(async () => {
    try {
      dispatch(loginStart());
      const tokenResponse = await authService.completeAuthentication();

      if (!tokenResponse) {
        dispatch(loginStop());
        return;
      }

      const decodedAccessToken = decodeAccessToken(tokenResponse.accessToken);
      authStorage.saveRefreshToken(decodedAccessToken.sid, tokenResponse.refreshToken);
      dispatch(
        loginSuccess({
          accessToken: tokenResponse.accessToken,
          idToken: tokenResponse.idToken,
        }),
      );
    } catch (e) {
      dispatch(loginError(e.message));
    }
  });

export const getOrRefreshAccessToken = async (
  dispatch: ThunkDispatch<RootState, unknown, AnyAction>,
  getState: () => RootState,
): Promise<string> => {
  const accessToken = selectAccessToken(getState());
  const accessTokenDecoded = selectAccessTokenDecoded(getState());
  const { sid, exp } = accessTokenDecoded;

  if (isAccessTokenExpired(accessTokenDecoded)) {
    Logger.debug(`Refreshing expired tokens (exp=${exp})`);
    const refreshToken = authStorage.getRefreshToken(sid);
    const tokenResponse = await authService.refreshToken(refreshToken);
    authStorage.saveRefreshToken(sid, tokenResponse.refreshToken);

    dispatch(
      refreshTokens({
        accessToken: tokenResponse.accessToken,
        idToken: tokenResponse.idToken,
      }),
    );
    return tokenResponse.accessToken;
  }

  return accessToken;
};
